import {TreeItem, TreeView} from "@mui/lab";
import {Box, ClickAwayListener, Popper, TextField} from "@mui/material";
import {StyledCheckbox} from "components/form/CheckBox";
import {GridItemFull} from "components/ui/AppElements";
import {AppIcon} from "components/ui/AppIcon";
import {useEffect, useMemo, useRef, useState, useTransition} from "react";
import {CountriesWithStatesDataItem, TreeCountriesWithLocationItem} from "./types";
import {generateTree, getPathExpandedAfterSearch} from "./utils";

type TreeViewLocationWithStatesProps<T = any> = {
    entityName: string;
    entityList: CountriesWithStatesDataItem[];
    selectedEntityItems?: Record<string, any>[]; // data with selected items
    onChange?: (item: Record<string, T>) => void;
    entityTextName?: string;
    onToggleExpand?: (path: string[]) => void;
};

const TreeViewLocationWithStates = (props: TreeViewLocationWithStatesProps) => {
    const {entityName, entityList, selectedEntityItems, onChange, entityTextName, onToggleExpand} = props;

    const singleSelect = false;

    //hooks
    const [isPending, startTransition] = useTransition();
    const [selectedPaths, setSelectedPaths] = useState<string[]>([]);
    const [selectElement, setSelectElement] = useState<null | HTMLElement>(null);
    const [expanded, setExpanded] = useState<string[]>([]);
    const [filteredEntityList, setFilteredEntityList] = useState<CountriesWithStatesDataItem[]>([]);

    const tree = useMemo(() => {
        return generateTree(entityList);
    }, [entityList]);

    useEffect(() => {
        if (selectedEntityItems) {
            startTransition(() => {
                setSelectedPaths(selectedEntityItems.map((item) => item.path));
            });
        }
    }, [selectedEntityItems]);

    const open = Boolean(selectElement);
    const timerSearchField: any = useRef();
    const inputSearchField = useRef(null);

    const handleOnChange = (checked: boolean, item: TreeCountriesWithLocationItem) => {
        if (!item.name) return;
        let newSelectedPaths: string[] = [];
        if (checked) {
            //exception for level to prevent checking states
            if (item.level === 1) {
                //item level 1 checked => check all direct children
                newSelectedPaths = [...selectedPaths, ...item.children.map((it) => it.path)];
            } else if (item.level === 2) {
                newSelectedPaths = [...selectedPaths, item.path];
            } else if (item.level === 3) {
                // State selection => unchek parent path and add path
                newSelectedPaths = [...selectedPaths.filter((path) => path !== item.parentPath), item.path];
            }
        } else {
            newSelectedPaths = selectedPaths.filter((path) => !path.startsWith(item.path));
        }

        setSelectedPaths(newSelectedPaths);

        if (onChange) {
            const selectedItems = entityList.filter((item) => newSelectedPaths.includes(item.path));
            onChange(selectedItems);
        }
    };

    const handleClose = () => {
        setSelectElement(null);
        setFilteredEntityList(entityList);
    };

    const handleExpand = (event: React.SyntheticEvent, nodeIds: string[]) => {
        if (props.onToggleExpand) props.onToggleExpand(nodeIds);
        setExpanded(nodeIds);
    };

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchString = event.target.value;
        if (searchString && searchString.trim().length !== 0) {
            if (timerSearchField.current) {
                window.clearTimeout(timerSearchField.current);
            }
            //
            const results = preformSearch(searchString);
            //timerSearchField.current = window.setTimeout(() => {
            setFilteredEntityList(results);

            const resultsInTree = generateTree(results);
            const expandedList = getPathExpandedAfterSearch(resultsInTree);
            setExpanded(expandedList);
            if (onToggleExpand) onToggleExpand(expandedList);
            //}, 500);
        } else {
            resetSearch();
        }
    };
    const preformSearch = (searchString: string) => {
        const regex = new RegExp(`.*${searchString}.*`, "gi");

        return entityList.filter((item) => {
            const lastPartOfPath = item.path.split("/").pop();
            return lastPartOfPath?.search(regex) === 0;
        });
    };
    const resetSearch = () => {
        setFilteredEntityList([]);
        setExpanded([]);
        if (onToggleExpand) onToggleExpand([]);
    };

    const isIndeterminate = (item: TreeCountriesWithLocationItem) => {
        if (item.children.length === 0) return false;

        const isEveryChildChecked = item?.children.every((it: TreeCountriesWithLocationItem) => {
            return selectedPaths.includes(it.path);
        });

        const isSomeChildChecked = item?.children.some((it: TreeCountriesWithLocationItem) => {
            return selectedPaths.includes(it.path);
        });

        if (item.level === 1 && isEveryChildChecked) {
            return false;
        } else {
            return isSomeChildChecked;
        }
    };

    const buildTreeLabel = (props: { item: TreeCountriesWithLocationItem; parentRoot: boolean }): any => {
        const {item} = props;

        const isChecked = selectedPaths.some((selectedPath) => {
            if (selectedPath.startsWith(item.path)) {
                return true;
            }
            return selectedPath === item.path;
        });

        return (
            <div data-cy={"treeLabel"} className={"flex-h flex-align-middle"}>
                <Box sx={{width: "24px", textAlign: "center"}}>
                    {!(singleSelect && item.children?.length) && (
                        <StyledCheckbox
                            disableRipple
                            color={"primary"}
                            size={"small"}
                            indeterminate={isIndeterminate(item)}
                            checked={isChecked}
                            inputProps={{
                                style: {
                                    padding: 0,
                                },
                            }}
                            data-cy={"checkbox-" + item.path}
                            title={item.path}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                event.stopPropagation();
                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                event.stopPropagation();
                                handleOnChange(event.currentTarget.checked, item);
                            }}
                        />
                    )}
                </Box>
                <GridItemFull
                    data-cy={"treeLabelName"}
                    data-test-value={item.name}
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        paddingRight: "8px",
                    }}
                >
                    {item.name === "States" ? "Locations" : item.name}
                </GridItemFull>
            </div>
        );
    };

    const BuildTree = (props: { list: TreeCountriesWithLocationItem[] }): any => {
        const {list} = props;

        return list.map((treeListItem: TreeCountriesWithLocationItem, index: number) => {
            const label = buildTreeLabel({item: treeListItem, parentRoot: index === 0});

            if (treeListItem?.children?.length) {
                return (
                    <TreeItem key={treeListItem.code} nodeId={treeListItem?.path ?? ""} label={label}
                              data-cy={treeListItem?.name}>
                        <BuildTree list={treeListItem?.children}/>
                    </TreeItem>
                );
            } else {
                return <TreeItem key={treeListItem.code} nodeId={treeListItem.path ?? ""} label={label}/>;
            }
        });
    };
    return (
        <>
            <Box style={{maxWidth: "200px"}}>
                <TextField
                    data-cy={"treeViewInput-" + entityTextName}
                    className="hoverPointer"
                    onClick={(event) => {
                        setSelectElement(event.currentTarget);
                    }}
                    name={"name"}
                    value={selectedPaths.length > 0 ? `${selectedPaths.length} selected` : "Select Geography"}
                    style={{width: "100%", maxWidth: "200px"}}
                    placeholder={`Pick ${entityName}`}
                    inputProps={{spellCheck: "false"}}
                    InputProps={{
                        endAdornment: (
                            <div style={{marginRight: "0.5rem", cursor: "pointer"}}>
                                <AppIcon display={"block"} color={"#00B5E2"} fontSize={"tiny"} rotate={open ? 180 : 0}
                                         icon={"ChevronDownIcon"}/>
                            </div>
                        ),
                    }}
                />
            </Box>
            <Popper
                sx={{
                    margin: "1rem",
                    maxHeight: "calc(100% - 2rem)",
                    zIndex: 1000,
                    overflow: "hidden",
                    minWidth: "250px",
                }}
                id="countriesWithStatesSelector"
                data-cy={`popup-${entityTextName}`}
                open={open}
                anchorEl={selectElement}
                placement="bottom-start"
                modifiers={[
                    {
                        name: "preventOverflow",
                        enabled: true,
                        options: {
                            altAxis: true,
                            altBoundary: true,
                            tether: true,
                            rootBoundary: "document",
                            padding: 8,
                        },
                    },
                ]}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <Box
                        sx={{
                            border: "1px solid rgba(27,31,35,.15)",
                            boxShadow: "0px 0px 6px rgba(196, 211, 241, 0.85)",
                            borderRadius: "20px",
                            backgroundColor: "white",
                            maxHeight: "100%",
                            overflow: "auto",
                            padding: "1rem",
                        }}
                    >
                        <div style={{height: "100%", overflow: "auto"}}>
                            <Box sx={{py: 0, mb: 1}}>
                                <TextField
                                    inputRef={inputSearchField}
                                    autoFocus
                                    fullWidth={true}
                                    type={"search"}
                                    placeholder={`Search ${entityTextName ?? entityName}`}
                                    onChange={handleSearchInputChange}
                                    data-cy={"treeViewSearch-" + entityTextName}
                                    InputProps={{
                                        startAdornment: (
                                            <div style={{marginLeft: "0.5rem"}}>
                                                <AppIcon display={"block"} color={"#00B5E2"} fontSize={"tiny"}
                                                         icon={"SearchIcon"}/>
                                            </div>
                                        ),
                                    }}
                                />
                            </Box>
                            <GridItemFull>
                                <div style={{overflow: "auto", maxHeight: "450px"}} data-cy={"tree-list"}>
                                    <TreeView
                                        defaultCollapseIcon={<AppIcon fontSize={"small"} icon={"Minus"}/>}
                                        defaultExpandIcon={<AppIcon fontSize={"small"} icon={"Plus"}/>}
                                        expanded={expanded}
                                        onNodeToggle={handleExpand}
                                    >
                                        <BuildTree
                                            list={filteredEntityList.length ? generateTree(filteredEntityList) : tree}/>
                                    </TreeView>
                                </div>
                            </GridItemFull>
                        </div>
                    </Box>
                </ClickAwayListener>
            </Popper>
        </>
    );
};
export default TreeViewLocationWithStates;
