import React, {Fragment, useState} from "react";
import styled from "styled-components/macro";
import {MainFrame} from "../../components/templates/MainFrame";
import {ActionButtons} from "../../components/controls/ActionButtons";
import {actions} from "../../application/actions/actions";
import {Button, Divider, Typography} from "@mui/material";
import {objectApi} from "application/entities/dataApi";
import {AdminDashboardCounter} from "./components/AdminDashboardCounter";
import {SCard, SGut} from "pages/Dashboard/styledElements";
import {DashboardCardContent} from "./components/DashboardCardContent";
import {LastAudiencesTable} from "./components/LastAudiencesTable";
import {useAuthState} from "redux/slices/auth.slices";
import {useAccountTracker} from "pages/Agencies/useAccountTracker";
import {LastAccountsList} from "./components/LastAccountsList";
import {LastUsersList} from "./components/LastUsersList";
import useLocalStorageState from "use-local-storage-state";
import {GlobalAvatar} from "components/ui/GlobalAvatar";
import {pageUtils} from "application/pages/pages.utils";
import {PageId} from "application/pages/pages.config";
import {useNavigate} from "react-router";
import {useAdminPage} from "components/hooks/useAdminPage";
import {useQuery} from "react-query";
import {BackendError} from "types/backendError";
import {IOpenDialogAction} from "../../redux/slices/dialog.slices";
import {useOpenDialogWindow} from "../../components/live/DialogWindow";

// import { useDemoMode } from "../../components/hooks/useDemoMode";
// import { useFeatureFlag } from "../../stores/featureFlags.store";

const accountsApi = new objectApi.accounts();
const audiencesApi = new objectApi.audiences();
const dspApi = new objectApi.dspChannels();

export const EvorraDashboard = () => {
    //const [demoMode, setDemoMode] = useDemoMode();
    //const featFlagStore = useFeatureFlag((st) => st.featureFlags);
    const authState = useAuthState();
    useAccountTracker(1);
    useAdminPage();
    const dialogWindow = useOpenDialogWindow();

    const navigate = useNavigate();

    const headerButtons = <ActionButtons actions={actions.dashboard} vars={{}}/>;

    const [loading, setLoading] = React.useState({
        accounts: true,
        users: true,
        dsp: true,
        profiles: true,
    });

    const [displayWarning, setDisplayWarning] = useState<boolean>(true);


    const [nbAccounts, setNbAccounts] = React.useState(0);
    const [accountsList, setAccountsList] = React.useState([]);

    const [nbUsers, setNbUsers] = React.useState(0);
    const [usersList, setUsersList] = React.useState([]);

    const [nbAudiences, setNbAudiences] = React.useState(0);

    const [nbDsp, setNbDsp] = React.useState(0);

    const [nbProfiles, setNbProfiles] = React.useState(0);

    const [accountsBookmark] = useLocalStorageState<
        {
            id: number;
            companyName: string;
        }[]
    >("accountsBookmark", []);

    const [audienceManualStepCount, setAudienceManualStepCount] = React.useState(0);

    //warning new version

    let newVersionDialog = {
        buttonActions: {
            acceptButton: "Go to the new version",
            cancelButton: "Stay here",
        },
        description: "This version is no longer maintained, you can stay here and use the old version. Or you can go to the new version and use the latest features. Note that the new version is not yet fully operational and bugs may appear. You will be asked to login again.",
    };
    const newVersionWarning: IOpenDialogAction = {
        dialogId: "newVersionWarning",
        buttonActions: newVersionDialog.buttonActions,
        title: "New Version Available!",
        description: newVersionDialog.description,
        icon: "Info",
        onAccept: () => {
            window.open("https://main.doi634j66xwkw.amplifyapp.com", '_blank')
        },
    };
    newVersionWarning.onClose = () => {
        setDisplayWarning(false);
        dialogWindow.close("newVersionWarning");
    };

    if (displayWarning && window.location.hostname.split('.')[0] === 'ci') {
        dialogWindow.open(newVersionWarning);

    }


    const audiences = useQuery<any, BackendError>(
        ["audiences-admin-dashboard, audiences"],
        () => {
            return audiencesApi
                .search({
                    page: {pageSize: 30, pageNumber: 0},
                    sort: {sortBy: "id", sortDir: "desc"},
                })
                .then((res) => res);
        },
        {cacheTime: 60 * 60 * 1000, staleTime: 0}
    );

    React.useEffect(() => {
        if (audiences.data?.data) {
            setNbAudiences(audiences.data.data.detailedResults[0].result.totalElements);
            setLoading((prev) => ({...prev, audiences: false}));
        }
    }, [audiences.data]);

    const fetchData = React.useCallback(() => {
        accountsApi
            .search({
                page: {pageSize: 10, pageNumber: 0},
                sort: {sortBy: "creationTime", sortDir: "desc"},
            })
            .then((res) => {
                setNbAccounts(res.data.searchResult.totalElements);
                setAccountsList(res.data.searchResult.content);
            })
            .catch((err) => {
                console.error("Error while fetching accounts", err);
            })
            .finally(() => {
                setLoading((prev) => ({...prev, accounts: false}));
            });

        accountsApi
            .getGlobalUsers({
                page: {pageSize: 10, pageNumber: 0},
                sort: {sortBy: "id", sortDir: "desc"},
            })
            .then((res) => {
                setNbUsers(res.data.page.totalElements);
                setUsersList(res.data._embedded.companyUsers);
            })
            .catch((err) => {
                console.error("Error while fetching users", err);
            })
            .finally(() => {
                setLoading((prev) => ({...prev, users: false}));
            });

        dspApi
            .get({})
            .then((res) => {
                setNbDsp(res.data._embedded.dspChannels.length);
            })
            .catch((err) => {
                console.error("Error while fetching dsp", err);
            })
            .finally(() => {
                setLoading((prev) => ({...prev, dsp: false}));
            });

        accountsApi
            .getDashBoard(1)
            .then((res) => {
                setNbProfiles(res.data.totalAddressableProfiles);
            })
            .catch((err) => {
                console.error("Error while fetching dashboard", err);
            })
            .finally(() => {
                setLoading((prev) => ({...prev, profiles: false}));
            });
        audiencesApi.getAudiencesManualStep().then((res) => {
            setAudienceManualStepCount(res.data._embedded.audiences?.length ?? 0);
        });
    }, []);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    const globalLoading = loading.accounts || loading.users || loading.dsp || loading.profiles;

    return (
        <MainFrame frameId={"EvorraDashboard"} headerProps={{headerButtons}} hideHeader={true} checkPageAccess={true}>
            <AdminWrap>
                {audienceManualStepCount > 0 && (
                    <AdminBanner>
                        <p style={{fontWeight: "900", fontSize: "1rem", marginBottom: "1rem"}}>
                            {audienceManualStepCount} {audienceManualStepCount > 1 ? " audiences are " : " audience is "} waiting
                            for a manual step
                        </p>
                        <p>
                            <Button variant="contained"
                                    onClick={() => navigate(pageUtils.getPagePathById(PageId.AudiencesManualStepList))}>
                                View audience{audienceManualStepCount > 1 ? "s" : ""}
                            </Button>
                        </p>
                    </AdminBanner>
                )}
                <Content>
                    <Typography variant="h1" style={{textAlign: "center", marginBottom: "1.5rem"}}>
                        Welcome to the admin dashboard {authState.user?.firstName}
                    </Typography>

                    <CounterWrap>
                        <AdminDashboardCounter loading={globalLoading} title={"Audiences"} count={nbAudiences}
                                               icon={"Audience"} pageId={"audience-admin-list"}/>
                        <AdminDashboardCounter loading={globalLoading} title={"Accounts"} count={nbAccounts}
                                               icon={"AccountEditIcon"} pageId={"accounts"}/>
                        <AdminDashboardCounter loading={globalLoading} title={"Users"} count={nbUsers} icon={"UserList"}
                                               pageId={"users"}/>
                        <AdminDashboardCounter loading={globalLoading} title={"Profiles Available"} count={nbProfiles}
                                               icon={"InterestBuilder"}/>
                        <AdminDashboardCounter loading={globalLoading} title={"Channels Available"} count={nbDsp}
                                               icon={"Channels"}/>
                    </CounterWrap>

                    <div style={{display: "flex", gap: "16px", margin: "0", marginBottom: "16px"}}>
                        <SGut style={{flex: 1, display: "flex", flexDirection: "column"}}>
                            <SCard>
                                <DashboardCardContent title="30 Last Audiences by status" icon="Audience">
                                    <LastAudiencesTable/>
                                </DashboardCardContent>
                            </SCard>
                            <div style={{display: "flex", gap: "16px", margin: "0", marginBottom: "16px"}}>
                                <SCard>
                                    <DashboardCardContent title="Last Accounts Created" icon="AccountEditIcon">
                                        <LastAccountsList accounts={accountsList} loading={loading.accounts}/>
                                    </DashboardCardContent>
                                </SCard>
                                <SCard>
                                    <DashboardCardContent title="Last Users Created" icon="UserList">
                                        <LastUsersList users={usersList} loading={loading.users}/>
                                    </DashboardCardContent>
                                </SCard>
                                <SCard>
                                    <DashboardCardContent title="Bookmarks" icon="Favorite" color="#f9ed60">
                                        <div style={{maxHeight: "350px", overflowY: "auto", width: "280px"}}>
                                            {accountsBookmark.length === 0 && (
                                                <>
                                                    <Typography variant="h5"
                                                                style={{textAlign: "center", fontSize: "14px"}}>
                                                        No bookmarked accounts
                                                    </Typography>
                                                    <Typography variant="body2" style={{marginTop: "0.5rem"}}>
                                                        You can add a bookmark in the account view to be able to
                                                        retrieve it here.
                                                    </Typography>
                                                    <Typography variant="body2" style={{marginTop: "0.5rem"}}
                                                                className="color-tech-grey">
                                                        Keep in mind that bookmarks are stored in your browser and will
                                                        not be available on another device or if you clear cache.
                                                    </Typography>
                                                </>
                                            )}
                                            {accountsBookmark.length > 0 &&
                                                accountsBookmark.map((bookmark, idx) => {
                                                    return (
                                                        <Fragment key={bookmark.id}>
                                                            <div
                                                                style={{
                                                                    display: "grid",
                                                                    gap: "5px",
                                                                    alignItems: "center",
                                                                    gridTemplateColumns: "31px 150px 53px",
                                                                    marginBottom: "0.5em",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <GlobalAvatar logoVal={bookmark.companyName}
                                                                              size={"26px"} fontSize={"13px"}
                                                                              showText={false}/>
                                                                <Typography
                                                                    variant="body1"
                                                                    title={bookmark.id + " - " + bookmark.companyName}
                                                                    style={{
                                                                        fontWeight: "600",
                                                                        fontSize: "12px",
                                                                        lineHeight: "unset",
                                                                        textOverflow: "ellipsis",
                                                                        whiteSpace: "nowrap",
                                                                        overflow: "hidden",
                                                                    }}
                                                                >
                                                                    {bookmark.companyName}
                                                                </Typography>
                                                                <div style={{textAlign: "right"}}>
                                                                    <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            navigate(pageUtils.getPagePathById(PageId.adminAccountView, {accountId: bookmark.id}));
                                                                        }}
                                                                    >
                                                                        View
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            {idx < accountsBookmark.length - 1 &&
                                                                <Divider style={{marginBlock: "0.5rem"}}/>}
                                                        </Fragment>
                                                    );
                                                })}
                                        </div>
                                    </DashboardCardContent>
                                </SCard>
                            </div>
                        </SGut>
                    </div>
                </Content>
            </AdminWrap>
        </MainFrame>
    );
};
export const AdminWrap = styled("div")({
    height: "100%",
    overflow: "auto",
});

export const Content = styled("div")`
    padding: 1.5rem;
    max-width: 1600px;
    margin-inline: auto;
    height: 100%;
`;
export const InnerContent = styled("div")`
    width: 100%;
    display: grid;
    justify-content: center;
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(360px, auto));
`;

const CounterWrap = styled("div")((props) => ({
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    marginBottom: "2rem",
}));

export const AdminBanner = styled("div")((props) => ({
    display: "block",
    width: "100%",
    padding: "1rem .5rem",
    backgroundColor: "#00b5e226",
    textAlign: "center",
}));
