import { Box, Button, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { actions } from "application/actions/actions";
import { objectApi } from "application/entities/dataApi";
import { listFilesColumns } from "application/entities/dataColumns/listFiles.columns";
import { IUploadedList } from "application/entities/dataTypes/listFiles";
import { dataColumnModelUtils } from "application/utils/dataColumnModel.utils";
import { ActionButtons } from "components/controls/ActionButtons";
import { useOpenDialogWindow } from "components/live/DialogWindow";
import { GridTable } from "components/organisms/GridTable";
import { AppIcon } from "components/ui/AppIcon";
import { UploadList } from "pages/PrePackagedLists/UploadList";
import { useTranslation } from "react-i18next";
import { IOpenDialogAction } from "redux/slices/dialog.slices";
import { ListTitle } from ".";

type DomainsListProps = {
  accountId: number;
  domainData:
    | {
        objects: IUploadedList[];
      }
    | undefined;
  domainLoading: boolean;
  domainRefetch: () => void;
};

export const DomainsList = (props: DomainsListProps) => {
  const dialogWindow = useOpenDialogWindow();
  const { t } = useTranslation();
  const accountsApi = new objectApi.accounts();

  const getFileName = (key: string) => key?.split("/").pop()?.replaceAll("_", " ");

  const prepareToDataGrid = (arr: IUploadedList[]): object[] => {
    const list = arr.map((item, index) => {
      const fileName = getFileName(item.key);
      return { ...item, id: index, accountId: props.accountId, fileName: fileName };
    });
    return list;
  };

  const uploadDomainListDialog: IOpenDialogAction = {
    dialogId: "UploadListModal",
    component: UploadList,
    title: t("uploadLists.uploadDomainList"),
    icon: "Upload",
    description: t("uploadLists.uploadDomainListDescription"),
    componentProps: {
      accountId: props.accountId,
      listType: "domain",
    },
  };

  const handleOpenDomainLists = () => {
    dialogWindow.open(uploadDomainListDialog).then();
  };
  const deleteList = (key: string) => {
    accountsApi.deleteUploadedList(props.accountId, key).then(() => {
      props.domainRefetch();
    });
  };
  const handleDelete = (key: string) => {
    dialogWindow.open({
      dialogId: "ListDelete",
      buttonActions: { acceptButton: true, cancelButton: true },
      title: "Delete this list?",
      description: "You will not be able to use it",
      icon: "Trash",
      onAccept: () => deleteList(key),
    });
  };

  actions.mylistsCard.deleteList.action = handleDelete;
  actions.mylistsCard.deleteList.code = "delete-list";
  actions.mylistsCard.deleteList.variant = "outlined";
  actions.mylistsCard.deleteList.color = "error";

  const columns: GridColDef[] = dataColumnModelUtils.renderModel(listFilesColumns, "show");
  columns[5].renderCell = (params) => {
    return (
      <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
        <ActionButtons actions={actions.mylistsCard} vars={params.row.key} />
      </div>
    );
  };

  const exclusionDomains = props.domainData?.objects ? prepareToDataGrid(props.domainData.objects) : [];

  return (
    <>
      <ListTitle title="Domain Lists">
        <Button disableRipple onClick={handleOpenDomainLists} variant="contained">
          Upload Domain List
        </Button>
      </ListTitle>
      {props.domainData?.objects.length === 0 ? (
        <EmptyLists />
      ) : (
        <GridTable
          autoHeight={true}
          dataCy={props.domainLoading ? "my-domains-table-loading" : "my-domains-table"}
          data={{ columns: columns as any, rows: exclusionDomains }}
          noRowsText={props.domainLoading ? "Loading" : "no domain lists"}
          gridProps={{
            localText: {
              noRowsLabel: "no label rows",
            },
          }}
        />
      )}
    </>
  );
};

const EmptyLists = () => {
  return (
    <Box textAlign="center" mt={4}>
      <AppIcon icon="TransparencyCard" color="#7587A3" fontSize="medium" />
      <Typography variant="h4" pt={2} textAlign="center" fontSize="16px" className="color-tech-grey">
        You don't have any list uploaded.
      </Typography>
    </Box>
  );
};
