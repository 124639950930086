import {Box} from "@mui/material";
import {profilesFormatter} from "_configuration/formaters";
import {FirstPartyDataFile} from "application/entities/dataTypes/firstPartyData";
import {useAudienceBuilderStore} from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import {featureColor, featureIcon} from "pages/AudienceBuilder/configuration";
import {AudienceFeatureNodeBox} from "../AudienceFeatureNodeBox";
import {FeatureLabel} from "../audienceFeature";

type ViewInclusionListProps = {
    viewMode: boolean;
};
export const ViewInclusionList = (props: ViewInclusionListProps) => {
    const {viewMode} = props;
    const audienceActions = useAudienceBuilderStore.getState().actions;
    const feature = useAudienceBuilderStore.getState().getFeatureByCode("INCLUSION_LIST").feature;

    const filtersValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        return el.code === "INCLUSION_LIST";
    })[0];

    const selected = filtersValues.selected as FirstPartyDataFile[];

    const handleRemoveFile = (fileCode: string) => {
        console.log("code", fileCode);
        const currentSelection = [...selected];

        const index = currentSelection.findIndex((current) => current.code === fileCode);
        if (index !== -1) {
            currentSelection.splice(index, 1);
            audienceActions.insertFeatureValues(feature, currentSelection);
        }
    };

    return (
        <>
            {selected.map((file) => {
                return (
                    <AudienceFeatureNodeBox
                        key={file.code}
                        viewMode={viewMode}
                        removeAction={() => handleRemoveFile(file.code)}
                        gutterColor={featureColor["INCLUSION_LIST"]}
                        icon={featureIcon["INCLUSION_LIST"]}
                    >
                        <Box sx={{
                            p: 0.5,
                            fontWeight: "bold",
                            textTransform: "capitalize",
                            lineBreak: "anywhere"
                        }}>{file.name}</Box>
                        <Box>
                            <FeatureLabel style={{maxWidth: "145px", marginRight: "0.5rem"}}
                                          title={"Uploaded Profiles"}>
                                {profilesFormatter.format(file.numRawProfiles ?? 0)} uploaded profiles
                            </FeatureLabel>
                            {file.useEnrichedProfiles && (
                                <FeatureLabel style={{maxWidth: "145px", marginRight: "0.5rem"}}
                                              title={"Enriched Profiles"}>
                                    {profilesFormatter.format(file.numEnrichedProfiles ?? 0)} enhanced profiles
                                </FeatureLabel>
                            )}
                        </Box>
                    </AudienceFeatureNodeBox>
                );
            })}
        </>
    );
};
