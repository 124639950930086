import { SelectionStepFormType } from "./types";

export const convertSummaryToFinalDspSelection = (summary: SelectionStepFormType[]) => {
  let params: any = {};
  summary.forEach((item) => {
    params[item.code] = {
      profilesNumber: Math.round(item.profiles),
      isImpressionBasedBuy: item.isImpressionBasedBuy || false,
      campaignName: "",
      checkoutParameters: {},
    };
    item.dspCheckoutParameterGroups.forEach((paramGroup) => {
      paramGroup.dspCheckoutParameters.forEach((param) => {
        if (param.selected) {
          params[item.code].checkoutParameters[param.code] = param.selected;
        }
      });
    });
  });
  return params;
};
