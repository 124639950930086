import {accessList} from "../access/access";
import {IAppPage} from "./pages.utils.types.tmp";

export type IAppPageConfig = Record<string, Record<string, IAppPage>>;

/**
 * first declare new pages here
 * pages are enums
 *
 */

export enum PageId {
    dashboardDataProvider = "dashboardDataProvider",
    EvorraDashboard = "EvorraDashboard",
    accountCreate = "create-accounts",
    accountList = "accounts",
    adminAccountView = "admin-account-view",
    accountView = "view-account",
    accountEdit = "edit-accounts",
    users = "users",
    userView = "user-view",
    userEdit = "user-edit",
    userCreate = "create-account-user",
    vaultPermissions = "permissions",
    vaultSetup = "data-vault-setup",
    DspAccountChannelParameters = "dsp-credentials",
    dataVault = "data-vault",
    globalCockpit = "data-cockpit",
    businessCockpit = "business-cockpit",
    myList = "my-lists",
    lists = "lists",
    reports = "reports",
    myDatas = "my-datas",
    uploadFirstParty = "upload-first-party",
    // user profiles
    userProfileView = "user-profile-view",
    userProfileUpdate = "user-profile-update",
    // audiences
    audienceActivation = "audience-activation",
    audienceBuilderSelection = "audience-builder-selection",
    audienceBuilderForm = "audience-builder-update",
    audienceBuilderFormTravel = "audience-builder-create-travel",
    audienceBuilderFormInterest = "audience-builder-create-interest",
    audienceBuilderFormAutomotive = "audience-builder-create-automotive",
    audienceBuilderFirstPartyData = "audience-builder-create-first-party-data",
    audienceBuilderCPG = "audience_builder-create-cpg",
    audienceLibrary = "audience-library",
    audienceApproval = "audience-approval",
    audienceView = "audience-view",
    audienceAdminList = "audience-admin-list",
    audienceSetManualStep = "audience-set-manual-step",
    AudiencesManualStepList = "audiences-manual-step-list",
    audiencesAlwaysOn = "audiences-always-on",
    channelsSetup = "channels-setup",
    channelsSetupAdd = "add-channel",
    channelsSetupEdit = "edit-channel",
    audienceEnablement = "audience-enablement",
    // authentication
    signin = "signin",
    verify_signup = "verify_signup",
    forgotten_code = "forgotten_code",
    retrieve_password = "retrieve_password",
    user_invitation = "user_invitation",
    user_activation = "user_activation",
    user_invitation_full = "user_invitation_full",
    // brand
    adminBrandList = "brand-admin-list",
    brandAgentList = "brand-agent-list",
    brandCreate = "brand-create",
    brandUpdate = "brand-update",
    brandView = "brand-view",
    brandDashboard = "brand-dashboard",
    brandAudienceLibrary = "brand-audience-library",
    brandsSettingsSuperAdmin = "brands-settings-super-admin",
    brandUserManagement = "brand-user-management",
    // company
    companyList = "company-list",
    companyCreate = "company-create",
    companyUpdate = "company-update",
    companyView = "company-view",
    companyDashboard = "company-dashboard",
    companyUserList = "company-list-users",

    //dev test
    newAudienceBuilder = "new-audience-builder",
}

/**
 * types for IAppPageConfig have been updated for access migration
 * IAppPageConfig._access is deprecated while used
 * IAppPageConfig._accesses is the new stand an must be defined
 * as an objectLink. eq  accessList : application/access/access.ts
 */
export const appPages: IAppPageConfig = {
    dashboard: {
        home: {
            id: PageId.dashboardDataProvider,
            path: "/homepage",
            icon: "DashboardIconMain",
            title: "Home",
            description1: "Navigate via the tiles below to key overview pages at a company or a user level.",
            description2: "From these overview pages, you can go into more detail.",
            _accesses: [],
            _routes: {
                adminBrandList: {
                    id: PageId.adminBrandList,
                    path: "/brands",
                    icon: "Brand",
                },
                create: {
                    id: PageId.brandCreate,
                    path: "/companies/:companyId/brands/create",
                    title: "Add New Brand",
                    description1: "Create a new brand",
                    icon: "DashboardIconBig",
                    _accesses: accessList.brands.create,
                },
                brandsSettingsSuperAdmin: {
                    id: PageId.brandsSettingsSuperAdmin,
                    path: "/companies/:companyId/brands/settings",
                    title: "Brands Settings",
                    description1: "Super Admin settings for brands",
                    icon: "DashboardIconBig",
                    _accesses: accessList.brands.superAdminSettings,
                },
                dashboard: {
                    id: PageId.brandDashboard,
                    path: "/companies/:companyId/brands/:brandId",
                    title: "Dashboard",
                    icon: "Brand",
                    _accesses: accessList.brands.dashboard,
                    _routes: {
                        view: {
                            id: PageId.brandView,
                            path: "/companies/:companyId/brands/:brandId/view",
                            icon: "Brand",
                            title: "details",
                            _accesses: accessList.brands.view,
                        },
                        agentList: {
                            id: PageId.brandAgentList,
                            path: "/companies/:companyId/brands/:brandId/users",
                            icon: "DashboardIconBig",
                            title: "User Management",
                            _accesses: accessList.brands.listUsers,
                        },
                        update: {
                            id: PageId.brandUpdate,
                            path: "/brands/:brandId/update",
                            icon: "Brand",
                            title: "Brand update",
                            _accesses: accessList.brands.edit,
                        },
                    },
                },
            },
        },
    },
    EvorraDashboard: {
        home: {
            id: PageId.EvorraDashboard,
            path: "/evorra-dashboard",
            icon: "DashboardIconMain",
            title: "Admin Dashboard",
            description1: "Navigate via the tiles below to key overview pages at a company or a user level.",
            description2: "From these overview pages, you can go into more detail.",
            _accesses: accessList.admin.evorraDashboard,
        },
    },

    /* brands -------- */
    brands: {
        agentList: {
            id: PageId.brandAgentList,
            path: "/companies/:companyId/brands/:brandId/users",
            icon: "DashboardIconBig",
            title: "User Management",
            _accesses: accessList.brands.listUsers,
        },
        // Dashboard brands routes insides company
        dashboard: {
            id: PageId.brandDashboard,
            path: "/companies/:companyId/brands/:brandId",
            title: "Brand Home",
            icon: "Brand",
            _accesses: accessList.brands.dashboard,
            _routes: {
                view: {
                    id: PageId.brandView,
                    path: "/companies/:companyId/brands/:brandId/view",
                    icon: "Brand",
                    title: "details",
                    _accesses: accessList.brands.view,
                },
                update: {
                    id: PageId.brandUpdate,
                    path: "/companies/:companyId/brands/:brandId/update",
                    icon: "Brand",
                    title: "Brand Update",
                    _accesses: accessList.brands.edit,
                },
                ChannelsSetup: {
                    id: PageId.channelsSetup,
                    path: "/accounts/:accountId/channels-setup",
                    title: "Channel Setup",
                    navTitle: "Channel Setup",
                    description1: "Connect your activation channels to enable Evorra audiences on them",
                    icon: "Channels", // Channels
                    _accesses: accessList.activation.setup,
                    _routes: {
                        ChannelsSetupAdd: {
                            id: PageId.channelsSetupAdd,
                            path: "/accounts/:accountId/add-channel",
                            title: "Add Channel",
                            navTitle: "Add Channel",
                            description1: "Channel configuration",
                            icon: "Channels", // Channels
                            _accesses: accessList.activation.setup,
                        },
                        ChannelsSetupEdit: {
                            id: PageId.channelsSetupEdit,
                            path: "/accounts/:accountId/edit-channel/:channelId",
                            title: "Edit Channel",
                            navTitle: "Edit Channel",
                            description1: "Channel edition",
                            icon: "Channels", // Channels
                            _accesses: accessList.activation.setup,
                        },
                    },
                },
                userManagement: {
                    id: PageId.brandUserManagement,
                    path: "/companies/:companyId/brands/:brandId/user-management",
                    icon: "UserList",
                    title: "User Management",
                    _accesses: accessList.brands.userManagement,
                },
                myList: {
                    id: PageId.myList,
                    path: "/accounts/:accountId/data-vault/my-lists",
                    title: "My Lists",
                    description1: `Upload your own audiences lists here.  Lists can then be selected via the Audience Builder and any profiles on them excluded from the specific audience segment you are building.`,
                    description2: ` Evorra hashes the information uploaded and only retains the hashed information, keeping your data private and safe.`,
                    icon: "MyListsIcon",
                    _accesses: accessList.prePackagedList.upload,
                },
                lists: {
                    id: PageId.lists,
                    path: "/accounts/:accountId/my-lists",
                    title: "My Lists",
                    description1: `You can upload various files to your account and use them to optimise the audiences you use on Evorra.`,
                    description2: `Email Lists. Here you can upload your own email or hashed email lists and utilise them as exclusions lists on any audience you are building \n Domain Lists. Here you can upload a list of URL’s or Domains and utilise them as either a whitelist or an exclusion list if you are enabling an audience on the platform as a Deal ID.`,
                    icon: "MyListsIcon",
                    _accesses: accessList.prePackagedList.upload,
                },
                myDatas: {
                    id: PageId.myDatas,
                    path: "/accounts/:accountId/my-datas",
                    title: "My Data",
                    description1: `You can upload data files to optimize your Evorra audiences.`,
                    description2: `First Party Data: Upload email, hashed email, and ID lists to include or exclude predefined audiences. \n Domain Lists: Upload domain or URL lists to use as a whitelist or exclusion list when enabling an audience as a Deal ID with Evorra’s SSP partners.`,
                    icon: "MyListsIcon",
                    _accesses: accessList.prePackagedList.upload,
                    _routes: {
                        uploadFirstParty: {
                            id: PageId.uploadFirstParty,
                            path: "/accounts/:accountId/upload",
                            title: "Upload First Party Data",
                            description1: `You can upload various files`,
                            description2: ``,
                            icon: "MyListsIcon",
                            _accesses: accessList.prePackagedList.upload,
                        },
                    },
                },
            },
        },
        view: {
            id: PageId.brandView,
            path: "/companies/:companyId/brands/:brandId/view",
            icon: "Brand",
            title: "details",
            _accesses: accessList.brands.view,
        },

        update: {
            id: PageId.brandUpdate,
            path: "/companies/:companyId/brands/:brandId/update",
            icon: "Brand",
            title: "Brand Update",
            _accesses: accessList.brands.edit,
        },
    },
    /* companies -------- */
    company: {
        adminCompanyList: {
            id: PageId.companyList,
            path: "/companies",
        },
        create: {
            id: PageId.companyCreate,
            path: "/companies/create",
        },

        dashboard: {
            id: PageId.companyDashboard,
            path: "/companies/:companyId",
            title: "Dashboard",
            icon: "CompanyIcon",
            _accesses: accessList.companies.dashboard,
            _routes: {
                // CMVP-2012
                /*agentList: {
                                  id: PageId.companyUserList,
                                  path: "/companies/:companyId/brands/:brandId/users",
                                  icon: "DashboardIconBig",
                                  title: "User Management",
                                  _accesses: accessList.brands.listUsers,
                                },
                                // Dashboard brands routes insides company
                                dashboard: {
                                  id: PageId.brandDashboard,
                                  path: "/companies/:companyId/brands/:brandId",
                                  title: "Brand Home",
                                  icon: "Brand",
                                  _accesses: accessList.brands.dashboard,
                                  _routes: {

                                    view: {
                                      id: PageId.brandView,
                                      path: "/companies/:companyId/brands/:brandId/view",
                                      icon: "Brand",
                                      title: "details",
                                      _accesses: accessList.brands.view,
                                    },
                                    agentList: {
                                      id: PageId.brandAgentList,
                                      path: "/companies/:companyId/brands/:brandId/users",
                                      icon: "DashboardIconBig",
                                      title: "User Management",
                                      _accesses: accessList.brands.listUsers,
                                    },
                                    update: {
                                      id: PageId.brandUpdate,
                                      path: "/companies/:companyId/brands/:brandId/update",
                                      icon: "Brand",
                                      title: "Brand Update",
                                      _accesses: accessList.brands.edit,
                                    },
                                  },
                                },
                                view: {
                                  id: PageId.brandView,
                                  path: "/companies/:companyId/brands/:brandId/view",
                                  icon: "Brand",
                                  title: "details",
                                  _accesses: accessList.brands.view,
                                },

                                update: {
                                  id: PageId.brandUpdate,
                                  path: "/companies/:companyId/brands/:brandId/update",
                                  icon: "Brand",
                                  title: "Brand Update",
                                  _accesses: accessList.brands.edit,
                                },*/
                companyView: {
                    id: PageId.companyView,
                    path: "/companies/:companyId/view",
                    icon: "CompanyIcon",
                    title: "company details",
                },
                companyUserList: {
                    id: PageId.companyUserList,
                    path: "/companies/:companyId/users",
                    icon: "DashboardIconBig",
                    title: "Brand users",
                    _accesses: accessList.companies.listUsers,
                },

                companyUpdate: {
                    id: PageId.companyUpdate,
                    path: "/companies/:companyId/update",
                    title: "Company update",
                    icon: "CompanyIcon",
                    _accesses: accessList.companies.edit,
                },
                library: {
                    id: PageId.brandAudienceLibrary,
                    path: "/brand/:accountId/audience-library",
                    title: "Library",
                    navTitle: "Audience Library",
                    description1: "Collection of created, purchased and archived audiences",
                    icon: "Audience",
                    _accesses: accessList.audiences.library,
                },
            },
        },
    },
    /* accounts -------- */
    accounts: {
        home: {
            id: PageId.accountList,
            path: `/accounts`,
            title: "Account List",
            icon: "CompanyListIcon",
            description1: "View and edit accounts and payments",
            description2: "Filter and download lists",
            _parent: PageId.EvorraDashboard,
            _accesses: accessList.accounts.list,
            _routes: {
                viewAccount: {
                    id: PageId.accountView,
                    path: `/accounts/view/:accountId`,
                    title: "View Account",
                    description1: "View my account",
                    icon: "AccountEditIcon",
                    _accesses: accessList.accounts.view,
                },
                editAccount: {
                    id: PageId.accountEdit,
                    path: `/accounts/update/:accountId`,
                    title: "Edit Account",
                    description1: "View and edit my account",
                    icon: "AccountEditIcon",
                    _accesses: accessList.accounts.edit,
                },
                createAccount: {
                    id: PageId.accountCreate,
                    path: `/create_account`,
                    title: "New Account",
                    icon: "AccountEditIcon",
                    _accesses: accessList.accounts.create,
                },
                editAccountNewUser: {
                    id: PageId.userCreate,
                    icon: "AccountEditIcon",
                    path: `/accounts/:accountId/create_user`,
                    _accesses: accessList.companyUsers.create,
                },
                adminView: {
                    id: PageId.adminAccountView,
                    path: `/accounts/admin-view/:accountId`,
                    title: "View Account",
                    description1: "View account details",
                    icon: "AccountEditIcon",
                    _accesses: accessList.accounts.adminView,
                    _parent: PageId.accountList,
                },
            },
        },
    },
    /* users -------- */
    users: {
        home: {
            id: PageId.users,
            path: `/users`,
            title: "User List",
            description1: "View, add, edit and delete users",
            description2: "Filter and download lists",
            icon: "UserList",
            _parent: PageId.EvorraDashboard,
            _accesses: accessList.companyUsers.list,
            _routes: {
                editUser: {
                    id: PageId.userEdit,
                    icon: "UserList",
                    path: `/users/:userId`,
                    title: "Profile edition",
                    _accesses: accessList.companyUsers.edit,
                },
                viewUser: {
                    id: PageId.userView,
                    icon: "UserList",
                    path: `/users/view/:userId`,
                    title: "My Profile",
                    _accesses: accessList.companyUsers.edit,
                },
                userProfileView: {
                    id: PageId.userProfileView,
                    icon: "UserList",
                    path: `/profile/:userId`,
                    title: "My Profile",
                    _accesses: accessList.companyUsers.view,
                },
                userProfileUpdate: {
                    id: PageId.userProfileUpdate,
                    icon: "UserList",
                    path: `/profile/:userId/update`,
                    title: "My Profile",
                    _accesses: accessList.companyUsers.edit,
                },
            },
        },
    },
    dataVault: {
        link: {
            id: PageId.vaultPermissions,
            path: "/accounts/:accountId/data-vault/rules",
            title: "Permissions",
            navTitle: "Permissions",
            description1: "View and edit Data Vault permissions",
            icon: "Permissions",
            _accesses: accessList.dataVault.permissions,
        },

        home: {
            id: PageId.dataVault,
            path: "/accounts/:accountId/data-vault/settings",
            title: "Data Vault",
            navTitle: "Data Vault",
            description1: "View and edit Data Vault settings",
            icon: "DataVaultListIcon",
            _accesses: accessList.dataVault.setup,
            _routes: {
                settings: {
                    id: PageId.vaultSetup,
                    path: "/accounts/:accountId/data-vault/settings",
                    title: "Data Vault Setup",
                    navTitle: "Setup",
                    description1: "View and edit Data Vault settings",
                    icon: "SettingsIcon",
                    _accesses: accessList.dataVault.setup,
                },
                DspAccountChannelParameters: {
                    id: PageId.DspAccountChannelParameters,
                    path: "/accounts/:accountId/activation-channels",
                    title: "Enablement Setup",
                    navTitle: "Enablement Setup",
                    description1: "Connect your activation channels to enable Evorra audiences on them",
                    icon: "Channels", // Channels
                    _accesses: accessList.activation.setup,
                },
            },
        },
    },
    audiences: {
        newBuilder: {
            id: PageId.newAudienceBuilder,
            path: "/new-audience-builder/:id",
            title: "Audience Builder",
            icon: "AudienceBuilderIcon",
            navTitle: "New Audience Builder",
            _accesses: accessList.audiences.builder,
        },
        library: {
            id: PageId.audienceLibrary,
            path: "/account/:accountId/audience-library",
            title: "Library",
            navTitle: "Audience Library",
            description1: "Collection of created, purchased and archived audiences",
            icon: "Audience",
            _accesses: accessList.audiences.library,
        },
        // legacy, unused ...
        builder: {
            id: "audience-builder-create",
            path: "/audience-builder/create",
            title: "Builder",
            icon: "AudienceBuilderIcon",
            _accesses: accessList.audiences.builder,
        },
        builderSelection: {
            id: "audience-builder-selection",
            path: "/audience-builder-selection/:accountId",
            title: "Create a new audience",
            icon: "AudienceBuilderIcon",
            _accesses: accessList.audiences.builder,
        },
        // builder travel
        builderTravel: {
            id: "audience-builder-create-travel", // unused, patch navLeft and delete
            path: "/audience-builder/create",
            title: "Travel Audiences",
            icon: "Travel",
            _accesses: accessList.audiences.builder,
        },
        // interest builder
        builderInterest: {
            id: PageId.audienceBuilderFormInterest,
            path: "/audience-builder/create",
            title: "Interest Audiences",
            icon: "InterestBuilder",
            _accesses: accessList.audiences.builder,
        },
        // automotive builder
        builderAutomotive: {
            id: PageId.audienceBuilderFormAutomotive,
            path: "/audience-builder/create",
            title: "U.S. Auto Audiences",
            icon: "Car",
            _accesses: accessList.audiences.builder,
        },
        // automotive builder
        builderFirstPartyData: {
            id: PageId.audienceBuilderFirstPartyData,
            path: "/audience-builder/create",
            title: "My Data Audiences",
            icon: "MyListsIcon",
            _accesses: accessList.audiences.builder,
        },
        builderCPG: {
            id: PageId.audienceBuilderCPG,
            path: "/audience-builder/create",
            title: "CPG Audiences",
            icon: "ShoppingCart",
            _accesses: accessList.audiences.builder,
        },
        // advanced (legacy)
        update: {
            id: PageId.audienceBuilderForm,
            path: "/audience/:id",
            title: "Advanced Audiences",
            icon: "AdvancedBuilder",
            _accesses: accessList.audiences.builder,
        },

        enablement: {
            id: PageId.audienceEnablement,
            path: "/audience-enablement/:audienceId",
            title: "Enable your audience",
            description: "",
            icon: "Channels",
            _accesses: accessList.audiences.builder,
            _parent: PageId.newAudienceBuilder,
        },
        approval: {
            id: PageId.audienceApproval,
            path: "/audience-library/approval/:audienceId",
            title: "Audience approval request",
            description: "",
            icon: "Approval",
            _accesses: accessList.audiences.approval,
        },
        view: {
            id: PageId.audienceView,
            path: "/audience/view/:audienceId",
            title: "Audience",
            description: "",
            icon: "Audience",
            _accesses: accessList.audiences.view,
        },
        adminView: {
            id: PageId.audienceAdminList,
            path: "/audiences-list",
            title: "All Audiences",
            navTitle: "Audiences",
            description: "",
            description1: "View and edit any audiences",
            description2: "Filter and search Audience",
            icon: "Audience",
            _accesses: accessList.audiences.view,
            _parent: PageId.EvorraDashboard,
            _routes: {
                AudienceSetManualStep: {
                    id: PageId.audienceSetManualStep,
                    path: "/audience/set-manual-step/:audienceId",
                    title: "Set manual step",
                    icon: "Audience",
                    _accesses: accessList.audiences.setDealId,
                    _parent: PageId.audienceAdminList,
                },
                audiencesSetDealIdList: {
                    id: PageId.AudiencesManualStepList,
                    path: "/audience/manual-step/",
                    title: "Manual step",
                    icon: "Audience",
                    _accesses: accessList.audiences.setDealId,
                    _parent: PageId.audienceAdminList,
                },
                audiencesAlwaysOn: {
                    id: PageId.audiencesAlwaysOn,
                    path: "/audience/audiences-always-on/",
                    title: "Always On Audiences",
                    icon: "Audience",
                    _accesses: accessList.audiences.setDealId,
                    _parent: PageId.audienceAdminList,
                },
            },
        },
    },
    dev: {
        medyll: {
            id: "medyll",
            path: "/dev/medyll",
            icon: "SubscriptionsIconBig",
            title: "test page",
        },
    },
    auth: {
        signin: {id: PageId.signin, path: "/signin", title: "nope"},
        verify_signup: {id: PageId.verify_signup, path: "/auth/verify_signup"},
        forgotten_code: {id: PageId.forgotten_code, path: "/auth/forgotten_code"},
        retrieve_password: {
            id: PageId.retrieve_password,
            path: "/auth/retrieve_password",
        },
        user_invitation: {
            id: PageId.user_invitation,
            path: "/auth/user_invitation/:email",
        },
        user_activation: {
            id: PageId.user_activation,
            path: "/auth/user_activation/:email",
        },
        user_invitation_full: {
            id: PageId.user_invitation_full,
            path: "/auth/user_invitation/:email/:password",
        },
    },
    globalcockpit: {
        home: {
            id: PageId.globalCockpit,
            path: `/data-cockpit`,
            title: "Data Cockpit",
            icon: "GlobalCockpitIcon",
            description1: "A quick view on the data available on this platform",
            _accesses: accessList.cockpit.report,
        },
        reports: {
            id: PageId.reports,
            path: `/global-reports`,
            title: "Reports",
            icon: "GlobalCockpitIcon",
            description1: "A quick view on the data available on this platform",
            _accesses: accessList.cockpit.report,
        },
    },
    businesscockpit: {
        home: {
            id: PageId.businessCockpit,
            path: `/business-cockpit`,
            title: "Business Cockpit",
            icon: "PaymentBillingIcon",
            description1: "A quick view on platform's business",
            _accesses: accessList.cockpit.report,
        },
    },

    companyAndDashboard: {},
};

export function getFlatPaths() {
    let tmpData: Record<string, any> = {};
    let lastId: any;

    JSON.stringify(appPages, (dta, nop) => {
        // if object got an id, it's a page
        if (nop?.id) {
            lastId = nop.id;
            tmpData[nop.id] = {...tmpData?.[nop.id], ...nop};
            // no need to keep _routes
            delete tmpData[nop.id]?._routes;
        } else {
            if (dta === "_routes" && lastId) {
                for (const no in nop) {
                    // register lastId as parent for all _routes
                    if (nop?.[no]?.id) {
                        tmpData[nop[no].id] = {...tmpData?.[nop[no].id], _parent: lastId};
                    }
                }
            }
        }

        return nop;
    });
    return tmpData;
}
