import { Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { styled } from "@mui/styles";
import { useAudienceBuilderList } from "components/hooks/useAudienceBuilderList";
import { useDemoMode } from "components/hooks/useDemoMode";
import { Columns } from "components/ui/AppElements";
import _ from "lodash";
import { useAudienceBuilderStore } from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import { featureTypesPresets } from "pages/AudienceBuilder/configuration";
import { IElementsData } from "pages/AudienceBuilder/types/audienceBuilderTypes";
import { useEffect } from "react";
import ReactFlow, { Controls, useReactFlow } from "react-flow-renderer";
import { useFeatureFlag } from "stores/featureFlags.store";
import { IFeaturesDto } from "../../../../application/entities/dataTypes/features";
import { AudienceCardMain } from "./AudienceCardMain";
import { AudienceFeatureNode } from "./AudienceFeatureNode";

export const AudienceFlowColumns = () => {
  const elements: any = [{ id: "red", type: "AudienceColumns", position: { x: 50, y: 0 }, data: {} }];
  const nodeTypes = {
    AudienceColumns: AudienceColumns,
  };

  return (
    <ReactFlow
      nodes={elements}
      snapToGrid={true}
      snapGrid={[10, 10]}
      color={"#DBE0EB"}
      connectionLineStyle={{ color: "#DBE0EB" }}
      defaultZoom={1.1}
      nodeTypes={nodeTypes as any}
      minZoom={0.7}
      maxZoom={1.3}
      zoomOnDoubleClick={false}
      zoomOnScroll={false}
      defaultPosition={[400, 200]}
      style={{ cursor: "grab" }}
    >
      <Controls showInteractive={false} showFitView={true} />
    </ReactFlow>
  );
};

export const AudienceColumns = () => {
  const [demoMode] = useDemoMode();
  const featFlagStore = useFeatureFlag((st) => st.featureFlags);
  const audienceId = useAudienceBuilderStore(
    (st) => st.audience.id,
    (old, ne) => _.isEqual(old, ne)
  );
  const audienceTypeId = useAudienceBuilderStore(
    (st) => st.audience?.audienceType?.id,
    (old, ne) => _.isEqual(old, ne)
  );
  const loadedDone = useAudienceBuilderStore((st) => st.isInit);
  const filtersValues = useAudienceBuilderStore((st) => st.filtersValues);
  const treeExpandedList = useAudienceBuilderStore((st) => st.treeExpandedList);

  const title = "Your audience card";

  const builderList = useAudienceBuilderList();

  const builder = builderList.find((item) => item?.id === audienceTypeId ?? 1);

  const columns = useAudienceBuilderStore(
    (st) => {
      const template = st.audienceTemplate;
      let columns: any = [];
      //prepare columns. There is a condition, cause template is made to build menu, we have to convert it to columns
      if (template.length === 1) {
        // for template with one tab in menu, we set 1 column by feature
        template[0].features.map((templateFeature) => {
          let cards: any = [];
          if (Array.isArray(templateFeature.subFeatures) && templateFeature.subFeatures.length > 0) {
            templateFeature.subFeatures.map((subFeature) => {
              cards.push(subFeature.code);
            });
          } else {
            cards.push(templateFeature.code);
          }
          columns.push({
            name: featureTypesPresets[templateFeature.code]?.title || templateFeature.name,
            cards: cards,
          });
        });
      } else {
        // for template with multiple tabs in menu, we set 1 column by tab
        template.map((templateItem) => {
          let cards: any = [];
          templateItem.features.map((templateFeature) => {
            // Feature flag feature_income
            if (templateFeature.code === "INCOME" && featFlagStore?.feature_income !== true) {
              return;
            }
            if (Array.isArray(templateFeature.subFeatures) && templateFeature.subFeatures.length > 0) {
              templateFeature.subFeatures.map((subFeature) => {
                cards.push(subFeature.code);
              });
            } else {
              cards.push(templateFeature.code);
            }
          });
          columns.push({
            name: templateItem.featureGroupName,
            cards: cards,
          });
        });
      }
      return columns;
    },
    (old, ne) => _.isEqual(old, ne)
  );

  // console.log('template', useAudienceBuilderStore.getState().audienceTemplate)
  //    console.log("🚀  columns:", columns)
  //console.log("🚀 ~ filtersValues:", filtersValues)
  // console.log("🚀 ~ treeExpandedList:", treeExpandedList)

  //refit view on change
  const { fitView } = useReactFlow();
  useEffect(() => {
    setTimeout(() => {
      fitView({ duration: 500 });
    }, 5);
  }, [filtersValues, treeExpandedList]);

  const isColumnEmpty = (column: any) => {
    let isEmpty = true;
    column.cards.map((card: any) => {
      if (filtersValues.find((filterValue) => filterValue.code === card && filterValue.selected.length > 0)) {
        isEmpty = false;
      }
    });
    return isEmpty;
  };

  return (
    <div style={{ cursor: "default" }}>
      <Columns sx={{ width: "100%" }}>
        <Box sx={{ pb: 2, display: "flex", flex: 1 }}>
          <div style={{ flex: 1 }}>
            <Box sx={{ pt: 0 }}>
              <Label sx={{ pb: 2 }}>{title}</Label>
              <AudienceCardMain id={audienceId} data={{} as IElementsData} viewMode={false} warning={builder?.warning} />
            </Box>
          </div>
        </Box>
      </Columns>

      <Columns>
        <AudienceColumnsContainer>
          {columns?.map((col: any, index: number) => {
            return (
              !isColumnEmpty(col) && (
                <GroupContainer key={col.name}>
                  <Label sx={{ pb: 2 }}>{col.name} </Label>
                  <Box sx={{ display: "grid", gap: "16px" }}>
                    {col.cards.map((card: any) => {
                      const filteredValues = [...filtersValues];
                      const result = filteredValues.filter(
                        (filterValue) => card === filterValue?.code && Array.isArray(filterValue?.selected) && filterValue?.selected.length > 0
                      );
                      return result.map((item) => {
                        return <AudienceFeatureNode code={card}>{item.code}</AudienceFeatureNode>;
                      });
                    })}
                  </Box>
                </GroupContainer>
              )
            );
          })}
        </AudienceColumnsContainer>
      </Columns>

      {loadedDone && <div data-cy="audienceLoaded" />}
    </div>
  );
};

interface DemoBoxProps {
  feature: IFeaturesDto;
}

const DemoBox = (props: DemoBoxProps) => {
  const { feature } = props;

  //const tour = useAudienceTourStore();

  // should display if tourCode === feature.code
  return (
    <Nada>
      <Box style={{ display: "flex", gap: "8px" }}>
        {/*<div>
       <AppIcon icon={'InfoIcon'} />
       </div>*/}
        <div>
          <Typography>About {feature.name} : </Typography>
          <Typography>- Deliver usage info</Typography>
          <Typography>- Show default value</Typography>
          {/* {(tour.activeFeature === feature.code) && <Button onClick={() => {tour.actions.gotIt(feature.code);}} variant={'outlined'} size={'small'}>Got it</Button>} */}
        </div>
      </Box>
    </Nada>
  );
};

const Nada = styled(Box)((props: { theme: Theme }) => ({
  color: "#7587A3",
  fontWeight: "bold",
  width: "170px",
  height: "90px",
  border: "5px dashed " + props.theme.skin.borderColor,
  borderRadius: "12px",
  padding: "0.25rem",
}));
const Label = styled(Box)((props) => ({
  color: "#7587A3",
  fontWeight: "bold",
}));
const AudienceColumnsContainer = styled(Box)((props: { theme: Theme }) => ({
  display: "flex",
  // margin: '16px',
  gap: "32px",
}));

const AudienceRowContainer = styled(Box)((props: { theme: Theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  gap: "32px",
}));

const GroupContainer = styled(Box)((props: { theme: Theme }) => ({}));
