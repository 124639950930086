import { Box, Slider, Typography, styled } from "@mui/material";
import { ColumnsWrap } from "components/ui/AppElements";
import _ from "lodash";
import { useAudienceBuilderStore } from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import { buildSliderDisplayValue, buildSliderMarkerLabel } from "pages/AudienceBuilder/audienceBuilderUtils";
import { sliderConfig } from "pages/AudienceBuilder/configuration";
import React from "react";
import { IFeaturesDto } from "../../../../application/entities/dataTypes/features";

interface FeatureTypeSliderProps {
  featureCode: string;
}

export const InputSlider = (props: FeatureTypeSliderProps) => {
  const { featureCode } = props;

  const audienceActions = useAudienceBuilderStore.getState().actions;

  const filtersValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
    return el.code === featureCode;
  })[0];
  const audienceTypeId = useAudienceBuilderStore((st) => st.audience?.audienceType?.id);

  const sliderConf = sliderConfig[featureCode];

  const locationFilterValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
    return el.code === "LOCATION";
  })[0];
  const countriesPathsWithIncome = ["United States", "Australia"];

  let isIncomeActive = () => {
    if (audienceTypeId === 1) {
      return Boolean(
        locationFilterValues?.selected.filter((selection) => {
          return countriesPathsWithIncome.some((str) => selection.path.includes(str));
        }).length
      );
    } else return true;
  };

  const feature = useAudienceBuilderStore.getState().getFeatureByCode(featureCode).feature;

  const timer = React.useRef<any>(null);
  const [stateFeatureValues, setStateFeatureValues] = React.useState<any>([filtersValues.selectable[0].min, filtersValues.selectable[0].max]);
  const [value, setValue] = React.useState<{ min: number; max: number }>(
    filtersValues.selected.length > 0 ? filtersValues.selected[0] : { min: 0, max: 0 }
  );

  React.useEffect(() => {
    if (!_.isEqual(filtersValues.selected, stateFeatureValues))
      setValue(filtersValues.selected.length > 0 ? filtersValues.selected[0] : { min: 0, max: 0 });
  }, [filtersValues.selected]);

  const handleChange = (event: Event, newValues: number | number[]) => {
    //update the dom
    if (typeof newValues !== "number") {
      setValue({ min: newValues[0], max: newValues[1] });
      setStateFeatureValues({ min: newValues[0], max: newValues[1] });

      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        audienceActions.insertFeatureValues(feature as IFeaturesDto, { min: newValues[0], max: newValues[1] });
      }, 300);
    }
  };

  const marksStep = filtersValues.selectable[0].max / 5;
  const marks = [
    {
      value: 0,
      label: buildSliderMarkerLabel(0, sliderConf),
    },
  ];
  for (let i = filtersValues.selectable[0].min + marksStep; i < filtersValues.selectable[0].max; i += marksStep) {
    marks.push({
      value: i,
      label: buildSliderMarkerLabel(i, sliderConf),
    });
  }

  marks.push({
    value: filtersValues.selectable[0].max,
    label: buildSliderMarkerLabel(filtersValues.selectable[0].max, sliderConf) + "+",
  });

  const Selection = () => {
    if (value.min === 0 && value.max === 0) {
      return null;
    }
    if (value.min === filtersValues.selectable[0].min && value.max === filtersValues.selectable[0].max) {
      return <StyledSelection data-cy="filter-selected">{buildSliderDisplayValue({ config: sliderConf, type: "all" })}</StyledSelection>;
    } else if (value.min === filtersValues.selectable[0].min && value.max < filtersValues.selectable[0].max) {
      return (
        <StyledSelection data-cy="filter-selected">
          {buildSliderDisplayValue({ config: sliderConf, type: "less", value: { max: value.max } })}
        </StyledSelection>
      );
    } else if (value.min > filtersValues.selectable[0].min && value.max === filtersValues.selectable[0].max) {
      return (
        <StyledSelection data-cy="filter-selected">
          {buildSliderDisplayValue({ config: sliderConf, type: "more", value: { min: value.min } })}
        </StyledSelection>
      );
    } else {
      return (
        <StyledSelection data-cy="filter-selected">
          {buildSliderDisplayValue({ config: sliderConf, type: "range", value: { min: value.min, max: value.max } })}
        </StyledSelection>
      );
    }
  };

  //doc for slider : https://mui.com/material-ui/react-slider/
  return (
    <>
      <Box sx={{ width: "100%", position: "relative" }}>
        {!isIncomeActive() ? (
          <StyledIncomeDisabled data-cy="income-disabled">
            <p style={{ color: "#7587a3" }}>
              To enable income filter, select at least one of the following countries from the{" "}
              <span
                style={{ cursor: "pointer", color: "#00b5e2" }}
                onClick={() => audienceActions.setActiveFeatureGroupCode(locationFilterValues.groupCode)}
              >
                location filter
              </span>
              : <br />
              - United States
              <br />- Australia
            </p>
          </StyledIncomeDisabled>
        ) : (
          <ColumnsWrap style={{ position: "relative" }}>
            {Selection && (
              <div style={{ width: "100%" }}>
                <Selection></Selection>
              </div>
            )}
            <StyledSlider
              getAriaLabel={() => "Income"}
              value={[value.min, value.max]}
              onChange={handleChange}
              valueLabelDisplay="off"
              marks={marks}
              step={sliderConf.step}
              disableSwap
              max={filtersValues.selectable[0].max}
              data-cy="slider-filter"
            />
          </ColumnsWrap>
        )}
      </Box>
    </>
  );
};

const StyledSelection = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  paddingBottom: "7px",
  color: "#7587a3",
  fontWeight: "600",
}));
const StyledIncomeDisabled = styled("div")(({ theme }) => ({
  borderRadius: "7px",
  padding: "0.5rem",
  backgroundColor: "#f5f5f5",
}));

export const StyledSlider = styled(Slider)(({ theme }) => ({
  color: "#b3b8bd",
  height: 3,
  padding: "4px 0px",
  position: "relative",
  maxWidth: "calc(100% - 5px)",

  "& .MuiSlider-thumb": {
    height: 16,
    width: 8,
    background: "linear-gradient(94.21deg, #00B5E2 0%, #10CAF9 100%)",
    boxShadow: "0px 0px 6px rgba(196, 211, 241, 0.85)",
    borderRadius: "132px",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
  },
  "& .MuiSlider-track": {
    height: 6,
    borderRadius: "4px",
  },
  "& .MuiSlider-rail": {
    color: "#E4EAF0",
    opacity: 1,
    height: 6,
    borderRadius: "4px",
    maxWidth: "calc(100% -15px)",
  },
  "& .MuiSlider-markLabel": {
    color: "#7587a3",
    top: "20px",
  },
}));
