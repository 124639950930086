import {
    Box,
    Divider,
    Fade,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Radio,
    styled,
    Switch,
    Typography,
    useTheme,
} from "@mui/material";
import {Columns, GridItem, GridItemFull, HeaderZone} from "../../ui/AppElements";
import {GlobalAvatar} from "../../ui/GlobalAvatar";
import {AppIcon} from "../../ui/AppIcon";
import React from "react";
import {useAuthActions, useAuthState} from "../../../redux/slices/auth.slices";
import {useQuery} from "react-query";
import {objectApi} from "../../../application/entities/dataApi";
import {useUserQuery} from "../../hooks/data/useUserQuery";
import {ICompanyUsers} from "../../../application/entities/dataTypes/companyUser";
import {pageUtils} from "../../../application/pages/pages.utils";
import {Link, useNavigate, useParams} from "react-router-dom";
import {ActionGranter} from "components/features/ActionGranter";
import {AUTHUser} from "../../../application/utils/AuthUser";
import {useDemoMode} from "../../hooks/useDemoMode";
import {useUiStore} from "../../../redux/ui.store";
import {accessList} from "../../../application/access/access";
import {EPermission, EUserRole, granter} from "../../../application/utils/granter.utils";
import {TIcon} from "../../../assets/icons";
import {PageId} from "../../../application/pages/pages.config";
import {stripeService} from "../../../application/services/stripe.service";
import {IAccounts} from "application/entities/dataTypes/accounts";

interface IUserMenuProps {
    anchor: null | HTMLElement;
    open: boolean;
    onClose: Function;
}

export const UserMenu = (props: IUserMenuProps) => {
    // receive brandId in uri.params or props, weak
    const uriParams = useParams<{ brandId: string; companyId: string }>() as {
        brandId: string;
        companyId: string;
    };
    const brandId = uriParams?.brandId ?? AUTHUser.accountId;
    const companyId = uriParams?.companyId ?? AUTHUser.accountId;

    const history = useNavigate();
    const authState = useAuthState();
    const theme = useTheme();
    const themeMode = useUiStore((st) => st.mode);
    const authActions = useAuthActions();
    const currentBrand = useUiStore((st) => st.currentBrand);

    const [demoMode, setDemoMode] = useDemoMode();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState<boolean>(false);
    const [billingPath, setBillingPath] = React.useState("");

    const isSuperAdmin = AUTHUser.isEvorraAdmin();

    // brandData
    const accountApi = new objectApi.accounts();
    const brandQy = useQuery(["account", companyId, "brandAccountAssociations"], () =>
        accountApi.getBrandAccountAssociations(AUTHUser.getUserAccount().id).then((res) => res)
    );
    const brandDataSet = brandQy.data?.data?.brandAccountAssociations ?? [];
    const brandData = brandDataSet.map((x: any) => x.subAccount);

    // userData
    const userQuery = useUserQuery({userId: AUTHUser.userId});
    const userData: ICompanyUsers = userQuery?.data ?? {};
    const userAssoc = !userData.agentBrandAccountAssociations ? [] : userData.agentBrandAccountAssociations.map((ass) => ass.account);

    const gridData = AUTHUser.isEvorraAdmin() || AUTHUser.permissions.accountGrade === EPermission.COMPANYADMIN ? brandData : userAssoc;

    // user in account.type === BRAND
    const accountType = AUTHUser.accountType ?? "DATAPROVIDER";

    const handleClose = () => {
        if (props.onClose) props.onClose();
    };
    const Logout = () => {
        authActions.doSignOut();
        setTimeout(() => {
            history("/");
        }, 1250);
    };

    React.useEffect(() => {
        setOpen(props.open);
        if (AUTHUser.getUserAccount().id && userData.role === "ADMINISTRATOR") {
            stripeService
                .getStripePortal(`${AUTHUser.getUserAccount().id}`, window.location.href)
                .then((res) => setBillingPath(res))
                .catch((error) => console.log({error}));
        }
    }, [props.open]);

    React.useEffect(() => {
        setAnchorEl(props.anchor);
    }, [props.anchor]);

    // if accountType === COMPANY then home is company dashboard, same for brand

    const linkHome =
        accountType === "COMPANY"
            ? pageUtils.getPagePathById(PageId.companyDashboard, {companyId: AUTHUser.getUserAccount().id})
            : accountType === "DATAPROVIDER"
                ? pageUtils.getPagePathById(PageId.dashboardDataProvider)
                : "/";

    const textLinkHome = granter.grantAccesses(accessList.accounts.edit).toUser(AUTHUser.granterUser) ? "Account Settings" : "My Account";
    const pageMyAccount = granter.grantAccesses(accessList.accounts.edit).toUser(AUTHUser.granterUser)
        ? accountType === "COMPANY"
            ? PageId.companyUpdate
            : PageId.accountEdit
        : accountType === "COMPANY"
            ? PageId.companyView
            : PageId.accountView;
    const pageProfile = granter.grantAccesses(accessList.companyUsers.edit).toUser(AUTHUser.granterUser)
        ? accountType === "COMPANY"
            ? PageId.userProfileUpdate
            : PageId.userEdit
        : accountType === "COMPANY"
            ? PageId.userProfileView
            : PageId.userEdit;
    const myProfileLink = pageUtils.getPagePathById(pageProfile, {
        userId: authState?.user?.id,
    });
    const myAccountLink = pageUtils.getPagePathById(PageId.companyView, {
        companyId: AUTHUser.getUserAccount().id,
        accountId: AUTHUser.getUserAccount().id,
    });
    const companyUserLink = pageUtils.getPagePathById(PageId.companyUserList, {
        brandId,
        companyId,
    });

    const brandSettingsLink = pageUtils.getPagePathById(PageId.brandsSettingsSuperAdmin, {companyId})

    // CMVP-2012 rename the dashboard link for the brand user
    let menuBase = [
        {
            code: "link-dashboard",
            text: accountType === "BRAND" ? "Brand Home" : "Dashboard",
            icon: accountType === "BRAND" ? "Brand" : "DashboardIcon",
            link: linkHome,
        },
        {
            code: "link-user-profile",
            text: "My Profile",
            icon: "MyProfile",
            link: myProfileLink,
        },
    ];

    const menuAdmin = [
        {
            code: "link-my-account",
            text: textLinkHome,
            icon: "SettingsIcon",
            link: myAccountLink,
        },
    ];
    menuAdmin.push({
        code: "link-user-management",
        text: "User Management",
        icon: "UserManagement",
        link: companyUserLink,
    });

    if (AUTHUser.isAccountSuperAdmin()) {
        menuAdmin.push({
            code: "link-brand-settings",
            text: "Brand Settings",
            icon: "SettingsIcon",
            link: brandSettingsLink,
        });
    }


    if (AUTHUser.isAccountSuperAdmin()) {
        menuAdmin.push({
            code: "link-billings",
            text: "Billings & Payments",
            icon: "Payments",
            link: billingPath,
        });
    }

    return (
        <Menu
            id="fade-menu-avatar"
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
        >
            <Box
                sx={{
                    backgroundColor: theme.palette.background.default,
                    width: "270px",
                }}
            >
                <HeaderZone>
                    <Columns spacing={1} alignItems={"center"}>
                        <GridItem>
                            <GlobalAvatar user={userData} isPending={userData.status !== "ACTIVATED"} size={"34px"}
                                          fontSize={"18px"}/>
                        </GridItem>
                        <GridItemFull>
                            <Typography
                                variant={"h6"}>{`${authState?.user?.firstName} ${authState?.user?.lastName}`}</Typography>
                            {authState?.user?.email}
                        </GridItemFull>
                    </Columns>
                </HeaderZone>
                <MenuList dense={true}>
                    {menuBase.map((menu) => (
                        <MenuItem data-cy={menu.code} dense={true} key={menu.code}>
                            <ListItemIcon>
                                <AppIcon fontSize={"small"} icon={menu.icon as TIcon}/>
                            </ListItemIcon>
                            <ListItemText>
                                <Link onClick={handleClose} style={{color: "inherit", textDecoration: "none"}}
                                      to={menu.link}>
                                    {menu.text}
                                </Link>
                            </ListItemText>
                        </MenuItem>
                    ))}
                    <ActionGranter accesses={[{grade: EPermission.COMPANYADMIN}]}>
                        {menuAdmin.map((menu) => (
                            <MenuItem data-cy={menu.code} dense={true} key={menu.code}>
                                <ListItemIcon>
                                    <AppIcon fontSize={"small"} icon={menu.icon as TIcon}/>
                                </ListItemIcon>
                                <ListItemText>
                                    {menu.code === "link-billings" ? (
                                        <a style={{color: "inherit", textDecoration: "none"}} href={menu.link}
                                           target="_blank">
                                            {menu.text}
                                        </a>
                                    ) : (
                                        <Link onClick={handleClose} style={{color: "inherit", textDecoration: "none"}}
                                              to={menu.link}>
                                            {menu.text}
                                        </Link>
                                    )}
                                </ListItemText>
                            </MenuItem>
                        ))}
                    </ActionGranter>

                    <Divider sx={{my: 1}} variant={"middle"}/>
                    {granter.grantAccesses([{role: EUserRole.DATA_CLIENT}]).toUser(AUTHUser.granterUser) &&
                        (userData.role === "ADMINISTRATOR" || userData?.agentBrandAccountAssociations?.length > 0) && (
                            <>
                                <BrandSelectorContainer>
                                    <div
                                        style={{
                                            position: "sticky",
                                            top: "0",
                                            backgroundColor: theme.palette.background.default,
                                            zIndex: "1000"
                                        }}
                                        className={"pad-1 pad-l-3"}
                                    >
                                        <Typography sx={{fontWeight: "bold"}}>Select Brand</Typography>
                                    </div>

                                    {userQuery?.data?.role === "ADMINISTRATOR" && accountType === "COMPANY" ? (
                                        <MenuItem disableGutters={true} dense={true} data-cy="add-new-brand-menu">
                                            <ListItemIcon>
                                                <AppIcon icon={"Brand"} fontSize={"small"}/>
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Link
                                                    onClick={handleClose}
                                                    style={{color: "inherit", textDecoration: "none"}}
                                                    to={pageUtils.getPagePathById(PageId.brandCreate, {companyId})}
                                                >
                                                    <Typography>Add New Brand</Typography>
                                                </Link>
                                            </ListItemText>{" "}
                                        </MenuItem>
                                    ) : null}
                                    {gridData.map((account: IAccounts) => {
                                        return (
                                            <MenuItem
                                                selected={Boolean(currentBrand?.id === account.id)}
                                                onClick={handleClose}
                                                disableGutters={true}
                                                dense={true}
                                                key={account.id}
                                            >
                                                <ListItemText>
                                                    <Link
                                                        data-cy="brand-item-menu-item"
                                                        data-cy-brand-id={account.id}
                                                        style={{color: "inherit", textDecoration: "none"}}
                                                        to={pageUtils.getPagePathById(PageId.brandDashboard, {
                                                            brandId: account.id,
                                                            companyId: companyId
                                                        })}
                                                    >
                                                        <div className="flex-h flex-align-middle gap-small">
                                                            <GlobalAvatar logoVal={account?.companyName} size={"26px"}
                                                                          fontSize={"14px"}/>
                                                            <Typography title={account.companyName} noWrap>
                                                                {account.companyName}
                                                            </Typography>
                                                        </div>
                                                    </Link>
                                                </ListItemText>
                                            </MenuItem>
                                        );
                                    })}
                                </BrandSelectorContainer>
                                <Divider sx={{my: 1}} variant={"middle"}/>
                            </>
                        )}

                    <MenuItem id={"cyLogoutButton"} data-cy={"logout"} onClick={Logout}>
                        <ListItemIcon>
                            <AppIcon icon={"SignOutIcon"}/>
                        </ListItemIcon>
                        Sign out
                    </MenuItem>
                    {isSuperAdmin && (
                        <>
                            <Divider sx={{my: 1}} variant={"middle"}/>
                            <MenuItem>
                                <ListItemIcon>
                                    <AppIcon fontSize={"small"} color={"#ccc"} icon={"VerticalEllipsis"}/>
                                </ListItemIcon>
                                <Typography
                                    onClick={() => {
                                        setDemoMode(!demoMode);
                                    }}
                                >
                                    Demo mode
                                </Typography>
                                <ListItemSecondaryAction>
                                    <Radio
                                        checked={Boolean(demoMode)}
                                        size={"small"}
                                        color={"primary"}
                                        name={"radio-test"}
                                        onClick={() => {
                                            setDemoMode(!demoMode);
                                        }}
                                    />
                                </ListItemSecondaryAction>
                            </MenuItem>
                        </>
                    )}
                    {isSuperAdmin && demoMode && (
                        <MenuItem>
                            <ListItemIcon></ListItemIcon>
                            <Typography
                                onClick={() => {
                                    useUiStore.setState({
                                        mode: themeMode === "light" ? "dark" : "light",
                                    });
                                }}
                            >
                                Theme
                            </Typography>
                            <ListItemSecondaryAction>
                                <Switch
                                    name={"theme"}
                                    checked={!Boolean(themeMode === "light")}
                                    onChange={() => {
                                        useUiStore.setState({
                                            mode: themeMode === "light" ? "dark" : "light",
                                        });
                                    }}
                                />
                            </ListItemSecondaryAction>
                        </MenuItem>
                    )}
                </MenuList>
            </Box>
        </Menu>
    );
};

const BrandSelectorContainer = styled(Box)({
    maxHeight: "250px",
    overflow: "auto",
});
