import {Button} from "@mui/material";
import {TreeViewInterest} from "components/form/EntityTreeView/TreeViewInterest";
import _ from "lodash";
import {AppIcon} from "components/ui/AppIcon";
import {useAudienceBuilderStore} from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import {jsonDecodeArray, jsonifyArray} from "pages/AudienceBuilder/audienceBuilderUtils";
import {useAppToast} from "../../../../components/live/AppToast";
import {useEffect} from "react";

export type InterestFeaturesMenuProps = {
    featureCode: string;
};

export const InterestFeaturesMenu = (props: InterestFeaturesMenuProps) => {
    const featureCode = props.featureCode;
    const audienceAccountId = useAudienceBuilderStore((st) => st.audience?.account?.id);
    const appToast = useAppToast();
    const audienceActions = useAudienceBuilderStore.getState().actions;
    const filtersValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        return el.code === featureCode;
    })[0];

    const evFeature = useAudienceBuilderStore(
        (st) => st.EvFeatures.find((feature) => feature.code === featureCode),
        (old, ne) => _.isEqual(old, ne)
    );

    const featureConf = evFeature?.featureValues ? jsonDecodeArray(evFeature?.featureValues)[0] : null;

    const interestSelects =
        filtersValues.selected.length > 0
            ? filtersValues.selected.sort((a: any, b: any) => a?.linkTo - b?.linkTo)
            : [
                {
                    featureId: null,
                    linkTo: null,
                    selected: [],
                },
            ];

    //if no interest, remove intent
    useEffect(() => {
        if (filtersValues.selected?.length === 0) {
            audienceActions.deleteFeature('INTENT')
        }
    }, [filtersValues.selected]);

    const addSelect = () => {
        audienceActions.addInterestSelect(interestSelects[interestSelects.length - 1]?.featureId);
    };

    const removeSelect = (id: number | null) => {
        audienceActions.removeInterestSelect(id);
    };

    const handleChange = (item: any, selectId?: number, selectIdx?: number) => {
        const newValues = item
            .map((item: any) => {
                return {
                    id: item?.id ?? item[featureConf.idField],
                    [featureConf.defaultField]: item[featureConf.defaultField],
                    [featureConf.hierarchyField]: item[featureConf.hierarchyField],
                };
            })
            .flat(1);


        //first select add edit when unique
        if (selectIdx === 0 && interestSelects.length === 1) {
            audienceActions
                .insertFeatureValues(evFeature!, jsonifyArray(newValues))
                .then(() => {
                })
                .catch((e) => {
                    console.error(e);
                });
        }

        //remove all selection in the first select when there is more than one select
        if (selectIdx === 0 && selectId && (interestSelects.length > 1) && (jsonifyArray(newValues)?.length === 0)) {
            appToast.open({
                toastId: 'globalToast',
                severity: 'error',
                message: 'You can remove all interests here because you have an "AND" condition.',
            });
            audienceActions.updateAndOperatorFeature(selectId, jsonifyArray(filtersValues.selected[0].selected))
            return
        }

        //first select edit when no unique
        if (selectIdx === 0 && selectId && interestSelects.length > 1) {
            audienceActions
                .updateAndOperatorFeature(selectId, jsonifyArray(newValues))
                .then(() => {
                })
                .catch((e) => {
                    console.error(e);
                });
        }

        //add select new
        if (selectId === 1 && selectIdx && selectIdx > 0) {
            audienceActions
                .createAndOperatorFeature(evFeature!.id, jsonifyArray(newValues))
                .then(() => {
                })
                .catch((e) => {
                    console.error(e);
                });
        }

        //edit "and" select item
        if (selectId !== 1 && selectId && selectIdx && selectIdx > 0) {
            //and select change
            audienceActions
                .updateAndOperatorFeature(selectId, jsonifyArray(newValues))
                .then(() => {
                })
                .catch((e) => {
                    console.error(e);
                });
        }
    };

    const handleMouseEnter = (id: any) => {
        if (interestSelects.length > 1) audienceActions.setHoverSelectId(id?.toString() ?? null);
    };
    const handleMouseLeave = () => {
        audienceActions.setHoverSelectId(null);
    };

    const onToggleExpand = (path: string[], featureCode: string) => {
        audienceActions.toggleTreeItem(featureCode, path);
    };

    return (
        <div>
            {interestSelects.map((interestSelect: any, idx: any) => {
                return (
                    <div key={idx} style={{marginBottom: "20px"}}>
                        <div
                            style={{display: "flex", alignItems: "center", gap: "7px"}}
                            onMouseEnter={() => handleMouseEnter(interestSelect?.featureId)}
                            onMouseLeave={handleMouseLeave}
                            data-cy="interest-select-id"
                            data-cy-value={interestSelect?.featureId ?? 0}
                        >
                            <TreeViewInterest
                                entityName={featureConf.entity.replace("{accountId}", `${audienceAccountId}`)}
                                entityList={filtersValues.selectable}
                                entityTextName={featureConf?.label ?? featureConf.entity}
                                defaultField={featureConf.defaultField ?? "name"}
                                hierarchyField={featureConf.hierarchyField}
                                onChange={handleChange}
                                selectId={interestSelect?.featureId ?? null}
                                selectIdx={idx}
                                selectedEntityItems={interestSelect.selected ?? []}
                                dataCy={`interest-select-${idx}`}
                                onToggleExpand={(path) => onToggleExpand(path, "interest" + interestSelect.featureId)}
                            />
                            {idx > 0 && (
                                <div
                                    data-cy={`remove-interest-select-${idx}`}
                                    style={{cursor: "pointer"}}
                                    onClick={() => {
                                        removeSelect(interestSelect?.featureId ?? null);
                                    }}
                                >
                                    <AppIcon icon="Trash" fontSize="small" color="red"/>
                                </div>
                            )}
                        </div>
                        {idx + 1 < interestSelects.length && (
                            <div
                                style={{
                                    marginInline: "auto",
                                    width: "50px",
                                    marginBlock: "10px",
                                    marginBottom: "-10px",
                                    display: "block",
                                    backgroundColor: "#02C697",
                                    color: "#fff",
                                    textAlign: "center",
                                    fontWeight: "800",
                                    borderRadius: "15px",
                                }}
                            >
                                AND
                            </div>
                        )}
                    </div>
                );
            })}
            {(interestSelects.length && interestSelects[interestSelects.length - 1]?.selected?.length) > 0 && (
                <div>
                    <Button data-cy="NewAndCondition" variant="outlined" size="small" onClick={addSelect}>
                        New "AND" condition
                    </Button>
                </div>
            )}
        </div>
    );
};
