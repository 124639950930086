import { objectApi } from "application/entities/dataApi";
import { IConfParameters } from "application/entities/dataTypes/accounts";
import { IAudiences } from "application/entities/dataTypes/audiences";
import { DspCampaignInfo } from "application/entities/dataTypes/dspCampaignInfo";
import { stripeService } from "application/services/stripe.service";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";
import { CardMethodsType, DspSelectionType, SelectionStepFormType, TStep, TStepActivation } from "../types";

export const EnablementSteps: Record<TStep, string> = {
  DspSelection: "Select Channels",
  EnablementOptions: "Configure Audience",
  Payment: "Select Billing",
  Approval: "Approval Request",
  Summary: "Review & Send",
  Final: "Confirmation",
};

type TEnablementStore = {
  accountConfParameters: IConfParameters;
  audienceData: IAudiences;
  billingAddress: any;
  currentStep: TStep;
  dspSelected: DspSelectionType;
  initDone: boolean;
  isCpmSetToZero: boolean;
  loading: boolean;
  loadingError: boolean;
  paymentMethods: Record<"card" | "sepa_debit", CardMethodsType[]>;
  paymentType: "card" | "invoice" | undefined;
  paymentInProgress: boolean;
  selectedCardMethod: CardMethodsType;
  selectionStepForm: SelectionStepFormType[];
  stepsActivation: TStepActivation[];
  totalProfilesImpressionBasedBuy: number;
  totalProfilesPayOnce: number;
  totalSpentPayOnce: number;
  approvalAmount: number;
  stepBackDisabled: boolean;
  actions: {
    reset: () => void;
    init: (id: number) => void;
    setCurrentStep: (step: TStep) => void;
    displayStep: (step: TStep) => void;
    hideStep: (step: TStep) => void;
    goToNextStep: () => void;
    goToPreviousStep: () => void;
    fetchPaymentMethods: (paymentMethod: "card" | "sepa_debit") => void;
    selectCard: (method: CardMethodsType) => void;
    selectInvoice: () => void;
    updateSelectionFormStep: (id: string, values: {}) => void;
    updateCheckoutParameters: (
      sspCode: string,
      dspCheckoutParameterGroupCode: string,
      checkoutParameterCode: string,
      value: boolean | string | string[]
    ) => void;
  };
};

const defaultSteps: TStepActivation[] = [
  {
    name: "DspSelection",
    label: "Channel Selection",
    isVisible: true,
  },
  {
    name: "EnablementOptions",
    label: "Enablement Options",
    isVisible: false,
  },
  {
    name: "Approval",
    label: "Approval Request",
    isVisible: false,
  },
  {
    name: "Payment",
    label: "Payment",
    isVisible: true,
  },
  {
    name: "Summary",
    label: "Summary",
    isVisible: true,
  },
  {
    name: "Final",
    label: "Confirmation",
    isVisible: true,
  },
];

const initValues: Omit<TEnablementStore, "actions"> = {
  accountConfParameters: {} as IConfParameters,
  audienceData: {} as IAudiences,
  billingAddress: {} as any,
  currentStep: "DspSelection",
  dspSelected: {},
  initDone: false,
  isCpmSetToZero: false,
  loading: true,
  loadingError: false,
  paymentMethods: { card: [], sepa_debit: [] },
  paymentType: undefined,
  paymentInProgress: false,
  selectedCardMethod: {} as CardMethodsType,
  stepsActivation: defaultSteps,
  totalProfilesImpressionBasedBuy: 0,
  totalProfilesPayOnce: 0,
  totalSpentPayOnce: 0,
  selectionStepForm: [],
  approvalAmount: 0,
  stepBackDisabled: false,
};

const audienceApi = new objectApi.audiences();
const accountApi = new objectApi.accounts();
const flagApi = new objectApi.accounts();

export const useAudienceEnablementStore = create<TEnablementStore>((set, get) => ({
  ...initValues,
  actions: {
    init: (audienceId) => {
      audienceApi
        .prepareActivation(audienceId)
        .then((res) => {
          if (res?.data) {
            flagApi.getMyFeatureFlags(res.data.account.id).then((flag) => {
              const featureFlags: Record<string, boolean> = flag?.data?.featureFlags ?? {};
              set({
                audienceData: res.data,
                loading: false,
                initDone: true,
                isCpmSetToZero: res.data.cpmMinConverted + res.data.cpmMaxConverted === 0 ? true : false,
                selectionStepForm: formatFormDatas(res.data.dspCampaignInfo, featureFlags),
              });
              accountApi.getAccountConfParameters(res.data.account.id).then((conf) => {
                if (conf?.data) {
                  set({ accountConfParameters: conf.data?.accountConfParameters });
                }
              });
              get().actions.fetchPaymentMethods("card");
            });
          }
        })
        .catch((e) => {
          console.log(e);
          set({ loading: false, loadingError: true });
        });
    },
    setCurrentStep: (step) => {
      set({ currentStep: step });
    },
    reset: () => {
      set({
        ...initValues,
      });
    },

    goToNextStep: () => {
      const state = get();
      const filteredVisibleSteps = state.stepsActivation.filter((s) => s.isVisible === true);
      const currentStepIndex = filteredVisibleSteps.findIndex((s) => s.name === state.currentStep);
      const nextStep = filteredVisibleSteps[currentStepIndex + 1].name;
      set({ currentStep: nextStep });
    },
    goToPreviousStep: () => {
      const state = get();
      if (state.paymentInProgress === true) {
        return;
      }
      const filteredVisibleSteps = state.stepsActivation.filter((s) => s.isVisible === true);
      const currentStepIndex = filteredVisibleSteps.findIndex((s) => s.name === state.currentStep);
      const nextStep = filteredVisibleSteps[currentStepIndex - 1].name;
      set({ currentStep: nextStep });
    },
    displayStep: (step) => {
      const newStepsActivation = get().stepsActivation.map((s) => {
        if (s.name === step) {
          s.isVisible = true;
        }
        return s;
      });
      set({ stepsActivation: newStepsActivation });
    },
    hideStep: (step) => {
      const newStepsActivation = get().stepsActivation.map((s) => {
        if (s.name === step) {
          s.isVisible = false;
        }
        return s;
      });
      set({ stepsActivation: newStepsActivation });
    },

    updateSelectionFormStep: (code, values) => {
      const currentSelectionFormValues = get().selectionStepForm;
      const currentSelectionFormValuesIndex = currentSelectionFormValues.findIndex((f) => f.code === code);
      const newSelectionFormValues = [...currentSelectionFormValues];
      newSelectionFormValues[currentSelectionFormValuesIndex] = {
        ...newSelectionFormValues[currentSelectionFormValuesIndex],
        ...values,
      };
      set({ selectionStepForm: newSelectionFormValues });
    },

    updateCheckoutParameters: (
      sspCode: string,
      dspCheckoutParameterGroupCode: string,
      checkoutParameterCode: string,
      value: boolean | string | string[]
    ) => {
      const currentSelectionFormValues = get().selectionStepForm;
      const selectionFormValueSspFiltered = currentSelectionFormValues.filter((f) => f.code === sspCode)[0];
      const updated = selectionFormValueSspFiltered.dspCheckoutParameterGroups.map((dspCheckoutParameterGroup) => {
        const newDspCheckoutParameters = dspCheckoutParameterGroup.dspCheckoutParameters.map((dspCheckoutParameter) => {
          if (dspCheckoutParameter.code === checkoutParameterCode) {
            return {
              ...dspCheckoutParameter,
              selected: value,
            };
          }
          return dspCheckoutParameter;
        });
        return {
          ...dspCheckoutParameterGroup,
          dspCheckoutParameters: newDspCheckoutParameters,
        };
      });
      const updatedSelectionFormValues = currentSelectionFormValues.map((f) => {
        if (f.code === sspCode) {
          return {
            ...f,
            dspCheckoutParameterGroups: updated,
          };
        }
        return f;
      });
      set({ selectionStepForm: updatedSelectionFormValues });
    },

    fetchPaymentMethods: (paymentMethod) => {
      const accountId = get().audienceData.account.id;
      stripeService.getCustomerPaymentMethods(accountId, paymentMethod).then((res: any) => {
        if (res) {
          set((st) => {
            const newState = { ...st, paymentMethods: { ...st.paymentMethods, [paymentMethod]: res.paymentMethods } };
            return newState;
          });
        }
      });
    },
    selectCard: (method) => {
      const newCard: CardMethodsType = {
        type: method.type,
        customer: method.customer,
        id: method.id,
        card: {
          brand: method.card.brand,
          last4: method.card.last4,
          expMonth: method.card.expMonth,
          expYear: method.card.expYear,
          country: method.card.country,
        },
      };
      set({ selectedCardMethod: newCard, paymentType: "card" });
    },
    selectInvoice: () => {
      set({ selectedCardMethod: {} as CardMethodsType, paymentType: "invoice" });
    },
  },
}));

//Display debug in React Dev Tools
if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("useAudienceEnablementStore", useAudienceEnablementStore);
}

const formatFormDatas = (dspCampaignInfo: DspCampaignInfo[], featureFlags: Record<string, boolean>) => {
  //convert dspCampaignInfo to selectionStepForm
  const selectionStepForm: SelectionStepFormType[] = [];
  dspCampaignInfo.forEach((dsp) => {
    if (!featureFlags?.dsp_facebook && dsp.groupCode === "facebook") return;
    dsp.dspItems.forEach((item) => {
      const form: SelectionStepFormType = {
        ...item,
        activationPlatform: dsp.groupName,
        activationPlatformCode: dsp.groupCode,
        desiredSpend: 0,
        desiredSpendDisplay: 0,
        dspGroup: dsp.groupName,
        profiles: 0,
        profilesDisplay: 0,
      };
      selectionStepForm.push(form);
    });
  });
  return selectionStepForm;
};
