import {Box, Divider, FormControlLabel, Switch, Typography} from "@mui/material";
import {profilesFormatter} from "_configuration/formaters";
import {FirstPartyDataFile} from "application/entities/dataTypes/firstPartyData";
import {StyledCheckbox} from "components/form/CheckBox";
import {useAudienceBuilderStore} from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import React from "react";

interface InputInclusionListProps {
    featureCode: string;
}

export const InputInclusionList = (props: InputInclusionListProps) => {
    const {featureCode} = props;

    const audienceActions = useAudienceBuilderStore.getState().actions;

    const accountId = useAudienceBuilderStore.getState().audience.account.id;

    const filtersValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        return el.code === featureCode;
    })[0];

    const exclusionLists = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        return el.code === "EXCLUSION_LIST";
    })[0];


    const feature = useAudienceBuilderStore.getState().getFeatureByCode(featureCode).feature;

    const selectable = filtersValues.selectable;
    const selected = filtersValues.selected as FirstPartyDataFile[];

    const cleanSelectionBeforeUpdate = (selection: FirstPartyDataFile[]): Array<Partial<FirstPartyDataFile>> => {
        return selection.map((list) => {
            return {
                code: list.code,
                numEnrichedProfiles: list.numEnrichedProfiles,
                numRawProfiles: list.numRawProfiles,
                name: list.name,
                useEnrichedProfiles: list?.useEnrichedProfiles ?? false,
            };
        });
    };

    const handleToggleFile = (featureValue: FirstPartyDataFile) => {
        const currentSelection = [...selected];

        const index = currentSelection.findIndex((current) => current.code === featureValue.code);
        if (index !== -1) {
            currentSelection.splice(index, 1);
        } else {
            currentSelection.push(featureValue);
        }
        audienceActions.insertFeatureValues(feature, cleanSelectionBeforeUpdate(currentSelection));
    };

    const toggleUseEnrichedProfiles = (fileCode: string) => {
        const currentSelection = [...selected];

        currentSelection.map((current) => {
            if (current.code === fileCode) {
                current.useEnrichedProfiles = current?.useEnrichedProfiles ? false : true;
            }
            return current;
        });
        audienceActions.insertFeatureValues(feature, cleanSelectionBeforeUpdate(currentSelection));
    };

    return (
        <Box sx={{maxHeight: "40vh", overflowY: "auto"}}>
            {selectable.map((list: FirstPartyDataFile, idx) => {
                const isUseEnrichedProfiles = selected.findIndex((selected) => selected.code === list.code && selected?.useEnrichedProfiles) > -1;
                return (
                    <React.Fragment key={list.code}>
                        <Box display="flex" alignItems="center" gap={1} mb="5px" sx={{cursor: "pointer"}}
                             title={exclusionLists.selected.includes(list.code) ? "You can't select this list because it's already selected as an exclusion list" : list.description}
                             onClick={() => handleToggleFile(list)}
                        >
                            <StyledCheckbox checked={selected.findIndex((file) => file.code === list.code) > -1}
                                            disabled={exclusionLists.selected.includes(list.code)}

                            />
                            <Typography variant="body1" fontWeight={900} sx={{wordBreak: 'break-all'}}>
                                {list.name}
                            </Typography>
                        </Box>
                        <Box ml={3}>
                            <Typography className="color-tech-grey" variant="body1">
                                {profilesFormatter.format(list.numRawProfiles ?? 0)} uploaded profiles
                            </Typography>
                            {(list.numEnrichedProfiles ?? 0) > 0 && (
                                <>
                                    <Typography className="color-tech-grey" variant="body1">
                                        {profilesFormatter.format(list.numEnrichedProfiles ?? 0)} enhanced profiles
                                    </Typography>
                                    {selected.findIndex((file) => file.code === list.code) > -1 && (
                                        <FormControlLabel
                                            sx={{mt: 1}}
                                            control={<Switch onChange={() => toggleUseEnrichedProfiles(list.code)}
                                                             checked={isUseEnrichedProfiles}/>}
                                            label="Use Enhanced Profiles"
                                        />
                                    )}
                                </>
                            )}
                        </Box>
                        {idx !== selectable.length - 1 && <Divider sx={{mb: 2, mt: 2}}/>}
                    </React.Fragment>
                );
            })}
        </Box>
    );
};
