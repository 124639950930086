import { TreeItem, TreeView } from "@mui/lab";
import { Box, ClickAwayListener, TextField } from "@mui/material";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/styles";
import { useOpenDialogWindow } from "components/live/DialogWindow";
import * as React from "react";
import styledOld from "styled-components/macro";
import { TEntityName } from "../../../application/entities/dataTypes";
import { Elem, GridItemFull } from "../../ui/AppElements";
import { AppIcon } from "../../ui/AppIcon";
import { StyledCheckbox } from "../CheckBox";

interface IEntityTreeViewProps<T = any> {
  entityName: TEntityName;
  entityList: EntityItem[];
  defaultField: string; // field used to display data
  hierarchyField: string; // field containing the path
  entityVars?: string[];
  selectedEntityItems?: EntityItem[]; // data with selected items
  onChange?: (item: Record<string, T>, selectId?: number, selectIdx?: number) => void;
  dataWatcher?: any;
  singleSelect?: boolean; // default true - allow multiple / single selection
  textOnEmpty?: string;
  entityTextName?: string;
  mode?: "float" | "block";
  ref?: any;
  selectId?: number;
  selectIdx?: number;
  dataCy?: string;
  onToggleExpand?: (path: string[]) => void;
}

interface ITreeItemData {
  name: string;
  parent?: string;
  path: string;
  entityData?: any;
}

interface TreeItemType extends ITreeItemData {
  name: string;
  children: TreeItemType[];
}

export interface EntityItem {
  id: number;
  condensedName: string;
  parentId: number;
  path: string;
  tier1: string;
  tier2: string;
  tier3: string;
  tier4: string;
  tier5: string;
  selected?: boolean;
  childrenSelected?: number;
}

const TreeViewSelectInterestRef = (props: IEntityTreeViewProps, ref: any) => {
  const {
    entityName,
    entityList,
    onChange,
    selectedEntityItems,
    defaultField,
    hierarchyField,
    singleSelect,
    entityTextName,
    dataCy,
    mode = "float",
    selectId,
    selectIdx,
  } = props;
  const [isPending, startTransition] = React.useTransition();
  const windowDialog = useOpenDialogWindow();
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

  const [inputValue, setInputValue] = React.useState<string>("");
  const [filteredEntityList, setFilteredEntityList] = React.useState<TreeItemType[]>([]);
  const [expanded, setExpanded] = React.useState<string[]>([]);

  const [treeList, setTreeList] = React.useState<TreeItemType[]>([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [entityListSelection, setEntityListSelection] = React.useState<EntityItem[]>([]); //list of items available in the treeview

  const inputSearchField = React.useRef(null);
  const timerSearchField: any = React.useRef();

  const timerChange: any = React.useRef(null);

  const open = Boolean(anchorEl);
  const id = `tree_${entityName}`;

  React.useImperativeHandle(ref, () => ({ inputValue }));

  React.useEffect(() => {
    //this is called on first render. EntityList and SelectedEntityItems are always set
    if (entityList.length) {
      startTransition(() => {
        setFilteredEntityList([]);
        initEntityListSelection(entityList);
        setInputValue(buildInputValue());
      });
    }
    return () => {
      setFilteredEntityList([]);
      setTreeList([]);
      setEntityListSelection([]);
      setInputValue("");
    };
  }, [entityList]);

  React.useEffect(() => {
    if (entityList.length > 0 && selectedEntityItems && Array.isArray(selectedEntityItems) && entityListSelection.length > 0) {
      updateEntityListSelection(selectedEntityItems);
      if (selectedEntityItems.length === 0) {
        //collaspes all items if no items are selected (reset or new audience)
        setExpanded([]);
      }
      setInputValue(buildInputValue());
    }
  }, [selectedEntityItems]);

  React.useEffect(() => {
    if (entityListSelection.length) {
      setTreeList(convertEntityListSelectionToTreeList(entityListSelection));
    }
  }, [entityListSelection]);

  //initialise the list used for the treeview
  function initEntityListSelection(list: EntityItem[]) {
    //convert SelectedEntityItem to EntityItem to be used in countchildren function
    const selectedEntityItemsConverted = [...selectedEntityItems!].map((entityItem) => {
      let [tier1, tier2, tier3, tier4, tier5] = entityItem.path.split("/");
      return {
        id: entityItem.id,
        condensedName: entityItem.condensedName,
        path: entityItem.path,
        tier1: tier1,
        tier2: tier2 ?? "",
        tier3: tier3 ?? "",
        tier4: tier4 ?? "",
        tier5: tier5 ?? "",
        parentId: 0,
      };
    });

    const generatedList = list.map((item) => {
      return {
        id: item.id,
        condensedName: item.condensedName,
        parentId: item.parentId,
        path: item.path,
        tier1: item.tier1,
        tier2: item?.tier2 ?? "",
        tier3: item?.tier3 ?? "",
        tier4: item?.tier4 ?? "",
        tier5: item?.tier5 ?? "",
        selected: selectedEntityItemsConverted?.filter((selectedItem) => selectedItem.id === item.id)?.length ? true : false,
        childrenSelected: Array.isArray(selectedEntityItemsConverted) ? countChildren(item, selectedEntityItemsConverted, true) : 0,
      };
    });
    setEntityListSelection(generatedList);
  }

  //count children selected (if this is a list of selected items, you must set listOfSelection to true)
  function countChildren(item: EntityItem, list: EntityItem[], listOfSelection: boolean = false) {
    if (item.tier1 && (item.tier2 === "" || item.tier2 === null)) {
      const children = list.filter((listItem) => listItem.tier1 === item.tier1);
      if (children) {
        return children.filter((listItem) => (listItem.selected || listOfSelection) && listItem.id !== item.id).length;
      }
    }
    if (item.tier2 && item?.tier3 === "") {
      const children = list.filter((listItem) => listItem.tier2 === item.tier2);
      if (children) {
        return children.filter((listItem) => (listItem.selected || listOfSelection) && listItem.id !== item.id).length;
      }
    }
    if (item.tier3 && item?.tier4 === "") {
      const children = list.filter((listItem) => listItem.tier3 === item.tier3);
      if (children) {
        return children.filter((listItem) => (listItem.selected || listOfSelection) && listItem.id !== item.id).length;
      }
    }
    if (item.tier4 && item?.tier5 === "") {
      const children = list.filter((listItem) => listItem.tier4 === item.tier4);
      if (children) {
        return children.filter((listItem) => (listItem.selected || listOfSelection) && listItem.id !== item.id).length;
      }
    }
    return 0;
  }

  //update the list used for the treeview with selected and childrenSelected count
  function updateEntityListSelection(listSelected: EntityItem[]) {
    if (entityListSelection.length) {
      const entityListSelectionEdited = [...entityListSelection];
      entityListSelectionEdited.map((item) => {
        if (listSelected.find((listItem) => listItem.id === item.id)) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
      //update count of children selected
      entityListSelectionEdited.map((item) => {
        item.childrenSelected = countChildren(item, entityListSelectionEdited);
      });
      setEntityListSelection(entityListSelectionEdited);
    }
  }

  //treelist is used by mui treeview
  function convertEntityListSelectionToTreeList(eList: EntityItem[]) {
    const tree: TreeItemType[] = [];
    //sort eList by path
    eList = eList.sort((a, b) => (a.path > b.path ? 1 : -1));
    for (let i = 0; i < eList.length; i++) {
      const path = eList[i]["path"];

      const pathSplice = path.split("/");

      let currentLevel = tree;

      for (let j = 0; j < pathSplice.length; j++) {
        const part = pathSplice[j];

        const existingPath = currentLevel.find((k) => k.name === part);

        if (existingPath) {
          // @ts-ignore
          currentLevel = existingPath.children;
        } else {
          const newPart = {
            name: part,
            path: pathSplice.slice(0, j + 1).join("/"),
            entityData: eList[i],
            children: [],
          };
          //
          currentLevel.push(newPart);
          currentLevel = newPart.children;
        }
      }
    }
    return tree;
  }

  /** Return array of path from an item preselection . props.selectedEntityItem at start*/
  const makeSelectedIds = (itemPreSelection: any) => {
    const array: string[] = [];

    itemPreSelection.forEach((itemData: any) => {
      if (itemData?.[defaultField]) {
        const path = itemData?.[hierarchyField];
        const pathSplice = path.split("/");
        for (let j = 0; j < pathSplice.length; j++) {
          array.push(pathSplice.slice(0, j + 1).join("/"));
        }
      }
    });
    return array;
  };

  function getTier(item: EntityItem) {
    if (item.tier1 !== "" && item.tier2 === "") return 1;
    if (item.tier2 !== "" && item.tier3 === "") return 2;
    if (item.tier3 !== "" && item.tier4 === "") return 3;
    if (item.tier4 !== "" && item.tier5 === "") return 4;
    if (item.tier5 !== "") return 5;
    return;
  }

  function handleOnChange(checked: boolean, item: TreeItemType) {
    if (item.entityData.childrenSelected > 0 && checked) {
      setIsDialogOpen(true);
      windowDialog.open({
        dialogId: "subfilters",
        buttonActions: { acceptButton: true, cancelButton: true },
        title: "You have filters selected",
        description: "By selecting this filter, you will remove " + item.entityData.childrenSelected + " subfilters. Do you want to continue?",
        onAccept: () => {
          processHandleOnChange(checked, item);
          setTimeout(() => {
            setIsDialogOpen(false);
          }, 1000);
        },
        onClose: () => {
          setTimeout(() => {
            setIsDialogOpen(false);
          }, 1000);
        },
      });
    } else {
      processHandleOnChange(checked, item);
    }
  }

  /** handle treeItem selection */
  function processHandleOnChange(checked: boolean, item: TreeItemType) {
    if (!item.name) return;
    const hasChildren = item.children && item.children.length > 0;
    const idxToUpdate = entityListSelection.indexOf(item.entityData);

    if (idxToUpdate !== -1) {
      entityListSelection[idxToUpdate].selected = checked;
    }

    //update children when parent is selected
    if (hasChildren && checked) {
      const tier = getTier(item.entityData);
      entityListSelection.map((entityItem) => {
        //ignore item selected, just update children
        if (entityItem.id !== item.entityData.id && entityItem.parentId !== item.entityData.parentId) {
          if (tier === 1 && entityItem.tier1 === item.entityData.tier1 && entityItem.tier2 !== "") {
            entityItem.selected = false;
          }
          if (tier === 2 && entityItem.tier2 === item.entityData.tier2 && entityItem.tier3 !== "") {
            entityItem.selected = false;
          }
          if (tier === 3 && entityItem.tier3 === item.entityData.tier3 && entityItem.tier4 !== "") {
            entityItem.selected = false;
          }
          if (tier === 4 && entityItem.tier4 === item.entityData.tier4 && entityItem.tier5 !== "") {
            entityItem.selected = false;
          }
        }
      });
      setEntityListSelection(entityListSelection);
      //collapse children if parent selected
      const idParent = expanded.findIndex((expandedItem) => expandedItem === item.path);
      if (idParent !== -1) {
        delete expanded[idParent];
        setExpanded(expanded);
      }
    }

    //update parents
    function getParents(item: EntityItem) {
      const parentsIds: number[] = [];
      if (item.parentId > 0) {
        parentsIds.push(item.parentId);
        const parent = entityListSelection.find((entityItem) => entityItem.id === item.parentId);
        if (parent) {
          parentsIds.push(...getParents(parent));
        }
      }
      return parentsIds;
    }

    const parentIds = getParents(item.entityData);

    parentIds.forEach((parentId) => {
      let parent = entityListSelection.find((entityItem) => entityItem.id === parentId);
      if (parent) {
        let idParentToUpdate = entityListSelection.indexOf(parent);
        entityListSelection[idParentToUpdate].selected = false;
      }
    });
    setEntityListSelection(entityListSelection);

    const selectionObj = entityListSelection?.filter((item) => item.selected) as Record<string, any>;

    if (onChange) {
      if (timerChange.current) clearTimeout(timerChange.current);
      timerChange.current = setTimeout(() => {
        onChange(selectionObj, selectId, selectIdx);
      }, 10);
    }
  }

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    if (props.onToggleExpand) props.onToggleExpand(nodeIds);
    setExpanded(nodeIds);
  };

  const handleInputClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilteredEntityList([]);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = event.target.value;
    if (searchString.length === 0) {
      resetSearch();
    }
    if (searchString && searchString.trim().length !== 0) {
      if (timerSearchField.current) {
        window.clearTimeout(timerSearchField.current);
      }
      const results = preformSearch(searchString);
      timerSearchField.current = window.setTimeout(() => {
        setFilteredEntityList(convertEntityListSelectionToTreeList(results));
        setExpanded(makeSelectedIds(results));
        if (props.onToggleExpand) props.onToggleExpand(makeSelectedIds(results));
      }, 500);
    } else {
      resetSearch();
    }
  };

  const resetSearch = () => {
    setFilteredEntityList([]);
    setExpanded([]);
    if (props.onToggleExpand) props.onToggleExpand([]);
  };

  const preformSearch = (searchString: string) => {
    const regex = new RegExp(`.*${searchString}.*`, "gi");
    const search = [...entityListSelection];
    return search.filter((item) => {
      return item.condensedName.search(regex) === 0;
    });
  };

  // forge root input value
  const buildInputValue = () => {
    return selectedEntityItems?.length === 0 ? "Select " + (entityTextName ?? entityName) : `${selectedEntityItems?.length} selected`;
  };

  const BuildTree = (props: { list: TreeItemType[] }): any => {
    const { list } = props;
    return list.map((treeListItem: TreeItemType, index: number) => {
      const label = buildTreeLabel({ item: treeListItem, parentRoot: index === 0 });

      if (treeListItem?.children?.length) {
        return (
          <TreeItem
            key={treeListItem.entityData.id}
            data-cy={treeListItem?.name}
            nodeId={treeListItem?.path ?? ""}
            label={label}
            expandIcon={
              <div style={{ position: "relative" }}>
                {" "}
                {Boolean(treeListItem.entityData?.childrenSelected > 0) ? (
                  <Bubble data-cy="filter-icon-count">
                    {treeListItem.entityData?.childrenSelected < 10 ? treeListItem.entityData?.childrenSelected : "9+"}
                  </Bubble>
                ) : (
                  ""
                )}
                <AppIcon
                  fontSize={"smallbis"}
                  color={"#00B5E2"}
                  icon={treeListItem.entityData.childrenSelected > 0 ? "ExclIncluFilled" : "ExclInclu"}
                />
              </div>
            }
          >
            <BuildTree list={treeListItem?.children} />
          </TreeItem>
        );
      } else {
        return <TreeItem key={treeListItem.entityData.id} data-cy={treeListItem?.name} nodeId={treeListItem.path ?? ""} label={label} />;
      }
    });
  };

  const buildTreeLabel = (props: { item: TreeItemType; parentRoot: boolean }): any => {
    const { item } = props;
    return (
      <div data-cy={"treeLabel"} className={"flex-h flex-align-middle"}>
        <Box sx={{ width: "24px", textAlign: "center" }}>
          {!(singleSelect && props.item.children?.length) && (
            <StyledCheckbox
              disableRipple
              color={"primary"}
              size={"small"}
              checked={item.entityData?.selected}
              inputProps={{
                style: {
                  padding: 0,
                },
              }}
              data-cy={"checkbox-" + item.path}
              title={item.path}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
                handleOnChange(event.currentTarget.checked, props.item);
              }}
            />
          )}
        </Box>
        <GridItemFull
          data-cy={"treeLabelName"}
          data-test-value={props.item.name}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingRight: "8px",
            position: "relative",
            fontWeight: item.entityData?.selected ? "bold" : "normal",
          }}
        >
          {props.item.name}{" "}
        </GridItemFull>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div style={{ maxWidth: "200px", width: "100%" }}>
        <TextField
          className="hoverPointer"
          data-cy={dataCy ?? "treeViewInput-" + entityTextName}
          data-cy-count={selectedEntityItems?.length ?? 0}
          onClick={handleInputClick}
          name={"name"}
          value={inputValue}
          style={{ width: "100%", maxWidth: "200px", cursor: "pointer" }}
          inputProps={{ style: { cursor: "pointer" }, spellCheck: "false" }}
          InputProps={{
            endAdornment: (
              <div style={{ marginRight: "0.5rem", cursor: "pointer" }}>
                <AppIcon display={"block"} color={"#00B5E2"} fontSize={"tiny"} rotate={open ? 180 : 0} icon={"ChevronDownIcon"} />
              </div>
            ),
          }}
        />
      </div>
      <PopperContainer
        id={id}
        data-cy={`popup-${entityTextName}`}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={!isDialogOpen ? handleClose : () => {}}>
          <InnerPopper>
            <div style={{ height: "100%", overflow: "auto" }}>
              <Box sx={{ py: 0, mb: 1 }}>
                <TextField
                  inputRef={inputSearchField}
                  autoFocus
                  fullWidth={true}
                  type={"search"}
                  placeholder={`Search ${entityTextName ?? entityName}`}
                  onChange={handleInputChange}
                  data-cy={"treeViewSearch-" + entityTextName}
                  InputProps={{
                    startAdornment: (
                      <div style={{ marginLeft: "0.5rem" }}>
                        <AppIcon display={"block"} color={"#00B5E2"} fontSize={"tiny"} icon={"SearchIcon"} />
                      </div>
                    ),
                  }}
                />
              </Box>
              <GridItemFull>
                <div style={{ overflow: "auto", maxHeight: "450px" }} data-cy={"tree-list"}>
                  <TreeView
                    className="interests-tree"
                    defaultCollapseIcon={<AppIcon fontSize={"small"} icon={"Minus"} />}
                    defaultExpandIcon={<AppIcon fontSize={"smallbis"} color={"#00B5E2"} icon={"Plus"} />}
                    expanded={expanded}
                    onNodeToggle={handleToggle}
                  >
                    <BuildTree list={filteredEntityList.length ? filteredEntityList : treeList} />
                  </TreeView>
                </div>
              </GridItemFull>
            </div>
          </InnerPopper>
        </ClickAwayListener>
      </PopperContainer>
    </React.Fragment>
  );
};
// @ts-ignore
export const TreeViewInterest = React.forwardRef(TreeViewSelectInterestRef);

const PopperContainer = styledOld(Popper)({
  margin: "1rem",
  maxHeight: "calc(100% - 2rem)",
  zIndex: 1000,
  overflow: "hidden",
  minWidth: "250px",
});

const InnerPopper = styledOld(Elem).attrs({ padding: [1, 1.5] })({
  border: "1px solid rgba(27,31,35,.15)",
  boxShadow: "0px 0px 6px rgba(196, 211, 241, 0.85)",
  borderRadius: 20,
  backgroundColor: "white",
  maxHeight: "100%",
  overflow: "auto",
});

const Bubble = styled("span")({
  color: "#fff",
  position: "absolute",
  zIndex: 10,
  right: "-3px",
  bottom: "-5px",
  width: "16px",
  height: "16px",
  border: "1px solid #fff",
  fontSize: "10px",
  textAlign: "center",
  backgroundColor: "#00B5E2",
  borderRadius: "50%",
  fontWeight: "bold",
});
