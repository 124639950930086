import {Box, useTheme} from "@mui/material";
import {IFeaturesDto} from "application/entities/dataTypes/features";
import {ISubFeaturesDto} from "application/entities/dataTypes/subFeatures";
import {DemoBox} from "components/ui/DemoBox";
import _ from "lodash";
import {useEffect, useState} from "react";
import {useFeatureFlag} from "stores/featureFlags.store";
import {IAccountConfParameters} from "types";
import {useAudienceBuilderStore} from "../Store/AudienceBuilderStore";
import {featureIcon, featureTypesPresets} from "../configuration";
import {EvFeature, TypeAudienceTemplate} from "../types/audienceBuilderTypes";
import {AudienceBuilderMenuBox} from "./AudienceBuilderMenuBox";
import {DeleteFeature} from "./CardsZone/Cards/DeleteFeature";
import {InputAge} from "./filters/InputAge";
import {InputArrayString} from "./filters/InputArrayString";
import {InputCheckList} from "./filters/InputCheckList";
import {InputInclusionList} from "./filters/InputInclusionList";
import {InputLinkedId} from "./filters/InputLinkedId";
import {InputPercentage} from "./filters/InputPercentage";
import {InputSlider} from "./filters/InputSlider";
import {InputSubFeatureDate} from "./filters/InputSubFeatureDate";
import {InputTemplateUrlList} from "./filters/InputTemplateUrlList";
import {InterestFeaturesMenu} from "./filters/InterestFeaturesMenu";
import {InputDate} from "./filters/InputDate";

export const AudienceBuilderFiltersMenu = (props: {
    template: TypeAudienceTemplate[];
    accountConfParameters: IAccountConfParameters | undefined;
}) => {
    const audienceBuilderTemplate = props.template;
    const accountConfParameters = props.accountConfParameters;
    const featFlagStore = useFeatureFlag((st) => st.featureFlags);
    const activeFeatureGroupCode = useAudienceBuilderStore(
        (st) => st.activeFeatureGroupCode,
        (old, ne) => _.isEqual(old, ne)
    );
    const audienceBuilderActions = useAudienceBuilderStore((st) => st.actions);

    const [featuresMenu, setFeaturesMenu] = useState<EvFeature[] | undefined>([]);
    const {palette} = useTheme();

    useEffect(() => {
        setFeaturesMenu(audienceBuilderTemplate?.find((t) => t.featureGroupCode === activeFeatureGroupCode)?.features);
    }, [activeFeatureGroupCode]);

    return (
        <Box style={{display: "flex", height: "100%"}}>
            <Box
                sx={{
                    backgroundColor: palette.background.default,
                    color: palette.text.primary,
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    height: "100%",
                    overflowX: "hidden",
                }}
                data-cy="filters-list"
            >
                {/* <span onClick={()=>console.log('filtersValues:', useAudienceBuilderStore.getState().filtersValues)}>Filtervalues</span> */}
                {featuresMenu?.map((feature) => {
                    const demoStep = {
                        id: feature.code,
                        order: feature.order,
                        onNext: () => {
                            if (feature.code === "LOCATION") {
                                audienceBuilderActions.setActiveFeatureGroupCode("STRATEGY");
                            }
                            if (feature.code === "INTENT") {
                                audienceBuilderActions.setActiveFeatureGroupCode("DEMOGRAPHICS");
                            }
                            if (feature.code === "GENDER") {
                                audienceBuilderActions.setActiveFeatureGroupCode("FILTERS");
                            }
                            if (feature.code === "EXCLUSION_LIST") {
                                audienceBuilderActions.setActiveFeatureGroupCode("TRAVEL");
                            }
                        },
                    };
                    //Feature flag
                    if (feature.code === "INCOME" && featFlagStore?.feature_income !== true) return null;
                    if (feature.code === "FAMILY_FILTER" && featFlagStore?.family_feature !== true) return null;
                    if (feature.code === "HOMEOWNERSHIP" && featFlagStore?.homeownership_feature !== true) return null;
                    if (feature.code === "POLITICAL_PARTY_AFFILIATION" && featFlagStore?.feature_politics !== true) return null;
                    if (feature.code === "POLITICAL_OUTLOOK" && featFlagStore?.feature_politics !== true) return null;
                    if (feature.code === "EDUCATION" && featFlagStore?.feature_education !== true) return null;
                    if (feature.code === "EXCLUSION_LIST" && !accountConfParameters?.enable_first_party_data) return null;

                    const subFeatureCount = Number(Array.isArray(feature.subFeatures) && feature.subFeatures.length);
                    return (
                        <Box key={feature.id} sx={{p: 1}}>
                            <DemoBox demoStep={demoStep}>
                                <AudienceBuilderMenuBox
                                    text={featureTypesPresets[feature.code]?.title ?? feature.name}
                                    icon={featureIcon[feature.code] ?? "BASICS"}
                                    helperText={featureTypesPresets[feature.code]?.helperText ?? "Text To set " + feature.code}
                                    subText={featureTypesPresets[feature.code]?.desc ?? "Text To set " + feature.code}
                                    deleteComponent={<DeleteFeature featureCode={feature.code}/>}
                                >
                                    {subFeatureCount === 0 && (
                                        <>
                                            {feature.code === "INTEREST" && feature.featureTypeCode === "linkedId" &&
                                                <InterestFeaturesMenu featureCode={feature.code}/>}

                                            {!["INTEREST", "AUTO_CURRENT_MODEL", "AUTO_INMARKET_MODEL", "CPG_PURCHASED_PRODUCT_TYPE"].includes(feature.code) &&
                                                feature.featureTypeCode === "linkedId" &&
                                                <InputLinkedId featureCode={feature.code}/>}
                                            {["AUTO_CURRENT_MODEL", "AUTO_INMARKET_MODEL", "CPG_PURCHASED_PRODUCT_TYPE"].includes(feature.code) && feature.featureTypeCode === "linkedId" && (
                                                <InputLinkedId featureCode={feature.code} treeInterest={true}/>
                                            )}

                                            {feature.featureTypeCode === "arrayString" && feature.code !== "AGE" &&
                                                <InputArrayString featureCode={feature.code}/>}
                                            {feature.featureTypeCode === "arrayString" && feature.code === "AGE" &&
                                                <InputAge featureCode={feature.code}/>}
                                            {feature.featureTypeCode === "percentage" &&
                                                <InputPercentage featureCode={feature.code}
                                                                 feature={feature as IFeaturesDto}/>}
                                            {feature.featureTypeCode === "templateUrlList" && feature.code === "EXCLUSION_LIST" && (
                                                <InputTemplateUrlList featureCode={feature.code}/>
                                            )}
                                            {feature.featureTypeCode === "templateUrlList" && feature.code === "INCLUSION_LIST" && (
                                                <InputInclusionList featureCode={feature.code}/>
                                            )}
                                            {feature.featureTypeCode === "slider" &&
                                                <InputSlider featureCode={feature.code}/>}

                                            {feature.featureTypeCode === "date" &&
                                                <InputDate featureCode={feature.code}/>}
                                        </>
                                    )}
                                    {subFeatureCount === 1 && (
                                        <>
                                            {feature.featureTypeCode === "checklist" && (
                                                <InputCheckList
                                                    featureCode={feature.code}
                                                    subFeatureCode={Array.isArray(feature.subFeatures) ? feature.subFeatures[0].code : ""}
                                                />
                                            )}
                                        </>
                                    )}

                                    {subFeatureCount > 1 && (
                                        <Box sx={{py: 0.25, display: "flex", flexWrap: "wrap", gap: "8px"}}>
                                            {Array.isArray(feature.subFeatures) &&
                                                feature.subFeatures.sort((a, b) => {
                                                    return a.order - b.order
                                                }).map((subFeature) => {
                                                    return (
                                                        <>
                                                            {subFeature.featureTypeCode === "date" &&
                                                                <InputSubFeatureDate
                                                                    subFeature={subFeature as unknown as ISubFeaturesDto}/>}
                                                            {subFeature.featureTypeCode === "linkedId" && (
                                                                <InputLinkedId featureCode={feature.code}
                                                                               subFeatureCode={subFeature.code}/>
                                                            )}
                                                        </>
                                                    );
                                                })}
                                        </Box>
                                    )}
                                </AudienceBuilderMenuBox>
                            </DemoBox>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};
