import {Box, Button, ButtonGroup, Fade, Paper, Typography} from "@mui/material";
import {objectApi} from "application/entities/dataApi";
import {PageId} from "application/pages/pages.config";
import {useDomainLists} from "components/hooks/data/useListsQuery";
import {MainFrame} from "components/templates/MainFrame";
import {useAccountConfParameters} from "pages/Agencies/useAccountConfParameters";
import {useAccountTracker} from "pages/Agencies/useAccountTracker";
import {useState} from "react";
import {useQuery} from "react-query";
import {useParams} from "react-router";
import {DomainsList} from "./domainsList";
import {FirstParty} from "./firstParty";

export const MyDatas = () => {
    const {accountId} = useParams<{ accountId: string }>() as { accountId: string };
    useAccountTracker(+accountId);
    const accountsApi = new objectApi.accounts();

    const domainListsQuery = useDomainLists(+accountId);

    const accountConfParameters = useAccountConfParameters(+accountId);

    const {isLoading: domainLoading, data: domainData, error: domainError, refetch: domainRefetch} = domainListsQuery;

    const parentAccountId = useQuery(
        ["parentAccountId", +accountId],
        () => {
            return accountsApi.byId(+accountId);
        },
        {enabled: Boolean(accountId)}
    );
    const fpDActive = accountConfParameters.data?.enable_first_party_data || false;

    const [tabActive, setTabActive] = useState<number>(0);


    return (
        <MainFrame
            frameId={PageId.myDatas}
            scrollable={true}
            loading={domainLoading || accountConfParameters.isLoading}
            //restrict404={accountConfParameters.data && !accountConfParameters.data?.enable_first_party_data}
            breadCrumbProps={{params: {companyId: parentAccountId?.data?.parentCompanyId, brandId: accountId}}}
        >
            <Box textAlign="center" pt={4}>
                <ButtonGroup variant="contained" aria-label="outlined primary button group" disableElevation>
                    {fpDActive &&
                        <Button
                            onClick={() => {
                                setTabActive(0);
                            }}
                            variant={tabActive === 0 ? "contained" : "outlined"}
                        >{`First Party Data`}</Button>
                    }

                    <Button onClick={() => setTabActive(fpDActive ? 1 : 0)}
                            variant={tabActive === (fpDActive ? 1 : 0) ? "contained" : "outlined"}>{`Domain Lists (${
                        domainData?.objects.length || "0"
                    })`}</Button>
                </ButtonGroup>
            </Box>

            <Box sx={{height: "100%", overflowY: "auto", padding: "2rem"}} data-cy="my-datas-page">
                {fpDActive && <Box sx={{maxWidth: "1200px", margin: "auto"}}>
                    <Fade in={tabActive === (fpDActive ? 0 : 1)} timeout={750} unmountOnExit>
                        <Box hidden={tabActive !== (fpDActive ? 0 : 1)}>
                            <FirstParty accountId={accountId}/>
                        </Box>
                    </Fade>
                </Box>}

                <Fade in={tabActive === (fpDActive ? 1 : 0)} timeout={750} unmountOnExit>
                    <Box hidden={tabActive !== (fpDActive ? 1 : 0)}>
                        <Paper elevation={4} sx={{
                            maxWidth: "1200px",
                            margin: "auto",
                            backgroundColor: "white",
                            borderRadius: "7px",
                            padding: "2rem"
                        }}>
                            <DomainsList accountId={+accountId} domainData={domainData} domainLoading={domainLoading}
                                         domainRefetch={domainRefetch}/>
                        </Paper>
                    </Box>
                </Fade>
            </Box>
        </MainFrame>
    );
};

export const ListTitle = (props: { title: string; children: JSX.Element, withBorder?: boolean }) => {
    const {title, children, withBorder = false} = props;
    return (
        <Box mb={2} sx={{
            display: "grid",
            gridTemplateColumns: "1fr 300px",
            justifyContent: "space-between",
            alignItems: "center",
            ...(withBorder && {
                borderBottom: "1px solid #eee",
                paddingBottom: "1rem"
            })
        }}>
            <Typography variant="h2" sx={{fontSize: "18px"}}>
                {title}
            </Typography>
            <Box textAlign="right">{children}</Box>
        </Box>
    );
};
