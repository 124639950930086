import {Box, Button, InputAdornment, TextField, Typography} from "@mui/material";
import {objectApi} from "application/entities/dataApi";
import {getCurrencySymbol, moneyFormatter} from "application/utils/formatters.utils";
import {useAppToast} from "components/live/AppToast";
import {FieldZone} from "components/templates/InfoBlock";
import React from "react";
import {useForm} from "react-hook-form";
import {useOpenDialogWindow} from "components/live/DialogWindow";
import {CPMInfos} from "./CPMInfos";
import {CPMFormProps} from "./CPMFormType";

export const AdditionnalFixedCPM = (props: CPMFormProps) => {
    const {accountConfParams, currency, setFormSelected, brandId, fetchAccountConfParameters} = props;
    const appToast = useAppToast();
    const dialogWindow = useOpenDialogWindow();

    React.useEffect(() => {
        if (Object.keys(accountConfParams).length) {
            reset({
                agency_additional_cpm: accountConfParams.agency_additional_cpm,
            });
        }
    }, [accountConfParams]);

    const formCPM = useForm({
        mode: "all",
        defaultValues: {
            agency_additional_cpm: 0,
        },
    });

    const {
        watch,
        handleSubmit,
        reset,
        formState: {isValid, isDirty},
    } = formCPM;

    const agency_additional_cpm = formCPM.register("agency_additional_cpm", {
        required: false,
        min: 0,
        max: 10,
        valueAsNumber: true
    });

    const fixedFee = watch("agency_additional_cpm") || 0;

    const calculateCPM = (value: number) => {
        return value + fixedFee;
    };

    const accountApi = new objectApi.accounts();
    const onSubmit = (data: any) => {
        accountApi
            .postCustomerCreateUpdateAccountConfParameters(brandId, {
                paramName: "agency_additional_cpm",
                paramValue: data.agency_additional_cpm,
            })
            .then((res) => {
                appToast.success({
                    message: "CPM increase amout updated",
                });
                fetchAccountConfParameters(brandId);
            })
            .catch((e) => {
                appToast.error({
                    message: "Error, please try again",
                });
            });
    };
    const onError = (errors: any, e: any) => console.log(errors, e);

    const handleRemoveManagedCPM = () => {
        dialogWindow.open({
            dialogId: "removeManagedCPM",
            buttonActions: {acceptButton: true, cancelButton: true},
            title: "Remove your managed CPM?",
            description: "It will delete the CPM extra cost",
            icon: "Trash",
            onAccept: removeManagedCPM,
        });
    };

    const removeManagedCPM = () => {
        accountApi
            .postCustomerCreateUpdateAccountConfParameters(brandId, {
                paramName: "agency_additional_cpm",
                paramValue: 0,
            })
            .then((res) => {
                appToast.success({
                    message: "Managed CPM has been removed",
                });
                fetchAccountConfParameters(brandId);
                setFormSelected(null);
            });
    };

    return (
        <>
            <form onSubmit={(e) => e.preventDefault()}>
                <CPMInfos/>
                <Box textAlign="center" mt={2}>
                    <FieldZone title="CPM increase amount" code={"agency_additional_cpm"}>
                        <TextField
                            type="number"
                            {...agency_additional_cpm}
                            error={!!formCPM.formState?.errors?.agency_additional_cpm}
                            inputProps={{style: {textAlign: "left"}}}
                            InputProps={{
                                sx: {textAlign: "left", marginBottom: "0px"},
                                startAdornment: (
                                    <InputAdornment position="start" sx={{pl: "10px", mr: "0"}}>
                                        {getCurrencySymbol(currency)}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FieldZone>
                    {!!formCPM.formState?.errors?.agency_additional_cpm && (
                        <Typography color="error" mt={-2} mb={2}>
                            The amount should be between 0 and 10
                        </Typography>
                    )}
                </Box>
                {(isValid || !isDirty) && fixedFee > 0 && (
                    <Box maxWidth={450} mx="auto">
                        <Typography variant="body1" mb={1} textAlign="center">
                            This will add a fixed fee of {moneyFormatter(fixedFee, {currency: currency})} to the base
                            CPM for an audience
                        </Typography>
                        <Typography variant="body1" mb={1} textAlign="center">
                            For instance, if the base fee in Evorra for the CPM
                            is {moneyFormatter(1.5 ?? 0, {currency: currency})}, the CPM in the interface and
                            the activation end-point will now
                            be {moneyFormatter(calculateCPM(1.5) ?? 0, {currency: currency})}
                        </Typography>
                        <Typography variant="body1" mb={4} textAlign="center">
                            Only the {fixedFee > 0 && moneyFormatter(calculateCPM(1.5) ?? 0, {currency: currency})} CPM
                            will be shown
                        </Typography>
                    </Box>
                )}

                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingInline: "1rem",
                    paddingBottom: "0.5rem"
                }}>
                    <div className={"flex-h gap-small"}>
                        {fixedFee === 0 ? (
                            <Button onClick={() => setFormSelected(null)} color={"error"} variant={"outlined"}>
                                Cancel
                            </Button>
                        ) : (
                            <Button onClick={() => handleRemoveManagedCPM()} color={"error"} variant={"outlined"}>
                                Remove managed CPM
                            </Button>
                        )}
                    </div>
                    <div>
                        <Button form={"updateBrand"} disabled={!isValid || !isDirty} variant={"contained"}
                                onClick={handleSubmit(onSubmit, onError)}>
                            Save
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};
