import {Box, styled} from "@mui/material";
import {IFeaturesDto} from "application/entities/dataTypes/features";
import {ColumnsWrap} from "components/ui/AppElements";
import {useAudienceBuilderStore} from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import {featureTypesPresets} from "pages/AudienceBuilder/configuration";
import React, {useEffect} from "react";
import {IAudienceFeatureDto} from "../../../../application/entities/dataTypes/audienceFeature";
import {AppLabel} from "../../../../components/ui/AppLabel";

interface FeatureTypeArrayStringProps {
    featureCode: string;
}

export const InputArrayString = (props: FeatureTypeArrayStringProps) => {
    const {featureCode} = props;

    const audienceActions = useAudienceBuilderStore.getState().actions;
    const filtersValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        return el.code === featureCode;
    })[0];

    const feature = useAudienceBuilderStore.getState().getFeatureByCode(featureCode);

    const timer = React.useRef<any>(null);
    const [stateFeatureValues, setStateFeatureValues] = React.useState<any>(filtersValues.selected.length > 0 ? filtersValues.selected : []);

    useEffect(() => {
        setStateFeatureValues(filtersValues.selected.length > 0 ? filtersValues.selected : []);
    }, [filtersValues.selected]);

    const handleChange = (value: string) => {
        let newValues = [...stateFeatureValues] as IAudienceFeatureDto["featureValues"];
        if (newValues.includes(value)) {
            // delete
            newValues = newValues.filter((el: string) => el !== value);
        } else {
            // add
            newValues.push(value);
        }

        setStateFeatureValues(newValues);
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            audienceActions.insertFeatureValues(feature.feature as IFeaturesDto, newValues);
        }, 350);
    };

    const locationFilterValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        return el.code === "LOCATION";
    })[0];
    const countriesPathsWithIncome = ["United States", "Australia"];

    const interestFilterValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        return el.code === "INTEREST";
    })[0];

    let isHomeOwnershipActive = () => {
        if (featureCode === "HOMEOWNERSHIP") {
            return Boolean(
                locationFilterValues?.selected.filter((selection) => {
                    return countriesPathsWithIncome.some((str) => selection.path.includes(str));
                }).length
            );
        } else return true;
    };

    const isUsAudienceOnlyActive = () => {
        const featuresUsOnly = ["POLITICAL_PARTY_AFFILIATION", "POLITICAL_OUTLOOK"];
        if (featuresUsOnly.includes(featureCode)) {
            return Boolean(
                locationFilterValues?.selected.filter((selection) => {
                    return countriesPathsWithIncome.some((str) => selection.path.includes("United States"));
                }).length
            );
        } else return true;
    };

    const countriesPathsWithEducation = ["United States", "Australia"];

    let isEducationActive = () => {
        if (featureCode === "EDUCATION") {
            return Boolean(
                locationFilterValues?.selected.filter((selection) => {
                    return countriesPathsWithEducation.some((str) => selection.path.includes(str));
                }).length
            );
        } else return true;
    };

    if (!isHomeOwnershipActive()) {
        return (
            <Box sx={{width: "100%", position: "relative"}}>
                <StyledHomeownershipDisabled data-cy="income-disabled">
                    <p style={{color: "#7587a3"}}>
                        To enable Homeownership filter, select at least one of the following countries from the{" "}
                        <span
                            style={{cursor: "pointer", color: "#00b5e2"}}
                            onClick={() => audienceActions.setActiveFeatureGroupCode(locationFilterValues.groupCode)}
                        >
              location filter
            </span>
                        : <br/>
                        - United States
                        <br/>- Australia
                    </p>
                </StyledHomeownershipDisabled>
            </Box>
        );
    }

    if (!isUsAudienceOnlyActive()) {
        return (
            <Box sx={{width: "100%", position: "relative"}}>
                <StyledHomeownershipDisabled data-cy={`${featureCode}-disabled`}>
                    <p style={{color: "#7587a3"}}>
                        To enable {featureTypesPresets[feature.feature.code]?.title ?? feature.feature.name} filter,
                        select at least one of the following
                        countries from the{" "}
                        <span
                            style={{cursor: "pointer", color: "#00b5e2"}}
                            onClick={() => audienceActions.setActiveFeatureGroupCode(locationFilterValues.groupCode)}
                        >
              location filter
            </span>
                        : <br/>- United States
                    </p>
                </StyledHomeownershipDisabled>
            </Box>
        );
    }


    if (featureCode === "INTENT" && interestFilterValues.selected.length === 0) {
        return (
            <Box sx={{width: "100%", position: "relative"}}>
                <StyledHomeownershipDisabled data-cy={`${featureCode}-disabled`}>
                    <p style={{color: "#7587a3"}}>
                        To enable Intent filter, select at least one interest
                    </p>
                </StyledHomeownershipDisabled>
            </Box>
        );
    }

    if (!isEducationActive()) {
        return (
            <Box sx={{width: "100%", position: "relative"}}>
                <StyledHomeownershipDisabled data-cy="income-disabled">
                    <p style={{color: "#7587a3"}}>
                        To enable Education filter, select at least one of the following countries from the{" "}
                        <span
                            style={{cursor: "pointer", color: "#00b5e2"}}
                            onClick={() => audienceActions.setActiveFeatureGroupCode(locationFilterValues.groupCode)}
                        >
              location filter
            </span>
                        : <br/>
                        - United States
                        <br/>- Australia
                    </p>
                </StyledHomeownershipDisabled>
            </Box>
        );
    }

    return (
        <Box sx={{width: "100%", position: "relative"}}>
            <ColumnsWrap>
                {filtersValues?.selectable?.map((value: { label: string; value: string }, index: number) => {
                    // check if value is in defaultAudienceValues
                    const isSelected = stateFeatureValues?.includes(value.value) ? true : false;

                    return (
                        <AppLabel
                            selected={isSelected}
                            dataCy={"label" + value.value}
                            dataCode={isSelected ? "selected" : ""}
                            id={feature.feature!.id}
                            key={value.value}
                            noMaxWidth={["POLITICAL_OUTLOOK", "EDUCATION"].includes(featureCode) ? true : false}
                            index={index}
                            onClick={(id, index) => {
                                handleChange(value.value);
                            }}
                            text={`${value.label}`}
                        />
                    );
                })}
            </ColumnsWrap>
        </Box>
    );
};
const StyledHomeownershipDisabled = styled("div")(({theme}) => ({
    borderRadius: "7px",
    padding: "0.5rem",
    backgroundColor: "#f5f5f5",
}));
