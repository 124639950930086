import {styled} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {useFeatureFlag} from "stores/featureFlags.store";
import {useAudienceBuilderStore} from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import {IAudienceFeatureDto} from "application/entities/dataTypes/audienceFeature";
import {jsonifyArray, trans2Tree, TreeItemType} from "pages/AudienceBuilder/audienceBuilderUtils";
import {IFiltersValues} from "pages/AudienceBuilder/types/audienceBuilderTypes";
import {TreeViewVisualisation} from "./TreeVisualisation";
import {useDrop} from "react-dnd";

export type DropAudienceEvent = {
    audienceFeature: IAudienceFeatureDto;
    treeListItem: TreeItemType;
};
export type DrawAudienceFeatureslinkedIdProps = {
    filterValue: IFiltersValues;
    viewMode?: boolean;
};

type TFilterValueSelectedItem = {
    featureId: number;
    linkTo: number;
    selected: any[];
};

export const ItemTypes = {
    INTEREST: "interest",
};

export function ViewAudienceFeatureInterest(props: DrawAudienceFeatureslinkedIdProps) {
    const {filterValue, viewMode} = props;
    const featFlagStore = useFeatureFlag((st) => st.featureFlags);

    const audienceStoreActions = useAudienceBuilderStore.getState().actions;
    const hoverSelectId = useAudienceBuilderStore((st) => st.hoverSelectId);

    const hierarchyField = "path";

    const dropData = (sourceAudienceFeatureId: number, targetAudienceFeatureId: number, selection: any) => {
        audienceStoreActions.dropInExistingZone(sourceAudienceFeatureId, targetAudienceFeatureId, jsonifyArray([selection.data]));
    };
    const firstList = filterValue.selected.filter((value) => value.linkTo === 0)[0] as unknown as TFilterValueSelectedItem;

    type IThumbProps = {
        filterValueSelectedItem: TFilterValueSelectedItem;
    };
    //for data-cy
    let listIterationNumber: number = -1;

    const Thumb = (props: IThumbProps) => {
        let {filterValueSelectedItem} = props;
        listIterationNumber++;


        const getAcceptList = () => {
            let list = filterValue.selected.map((filterValue) => filterValue.featureId.toString());


            list = list.filter((item) => item !== filterValueSelectedItem.featureId.toString());
            //don't allow the first item to be dropped on the second if there is one value
            const linktoZeroSelected = filterValue.selected.find((filterValueSelected) => filterValueSelected.linkTo === 0);
            if (linktoZeroSelected.selected.length < 2) {
                list = list.filter((item) => item !== linktoZeroSelected.featureId.toString());
            }
            return list;
        };

        const [{isOver, canDrop}, drop] = useDrop(() => ({
            accept: getAcceptList(),
            canDrop: (item: any) => {
                return item.featureId !== filterValueSelectedItem.featureId;
            },
            drop: (monitor: any) => {
                dropData(monitor.itemFeatureId, filterValueSelectedItem.featureId, monitor.treeListItem);
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            }),
        }));

        if (filterValueSelectedItem?.selected?.length === 0) {
            return <></>;
        }
        const nextList = filterValue.selected.filter(
            (value) => value.linkTo === filterValueSelectedItem.featureId
        )[0] as unknown as TFilterValueSelectedItem;

        const mode = "grouped";

        let isSelectInterestHover = Number(hoverSelectId) === filterValueSelectedItem?.featureId ? true : false;

        return (
            <>
                <ThumbGrid
                    ref={drop}
                    canDrop={canDrop}
                    isOver={isOver}
                    style={{maxWidth: "145px"}}
                    data-cy={"featureZone"}
                    data-code={"interestList" + listIterationNumber}
                    mode={mode}
                    className={"nowheel nodrag" + (isSelectInterestHover ? " hover" : "")}
                >
                    <TreeContainer>
                        <TreeViewVisualisation
                            tree={trans2Tree(filterValueSelectedItem.selected, hierarchyField)}
                            expandVisible={true}
                            itemFeatureId={filterValueSelectedItem.featureId}
                            expandCode={"interest" + filterValueSelectedItem.featureId}
                            isDragAndDropActive={viewMode ? false : filterValue.selected.length > 1 ? true : false}
                            viewMode={viewMode}
                        ></TreeViewVisualisation>
                    </TreeContainer>
                </ThumbGrid>

                {nextList && nextList.selected.length > 0 && (
                    <>
                        <div style={{position: "relative", zIndex: 50}}>
              <span style={{
                  padding: "3px",
                  position: "absolute",
                  top: "-16px",
                  left: "50%",
                  transform: "translate(-50%,0)"
              }}>
                <Joiner>AND</Joiner>
              </span>
                        </div>
                        <Thumb filterValueSelectedItem={nextList}/>
                    </>
                )}
            </>
        );
    };
    // console.groupEnd()

    return <Thumb filterValueSelectedItem={firstList}/>;
}

export const FeatureGrid = styled("div")((props: { theme: Theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    gap: "4px",
    padding: "4px",
}));
export const Joiner = styled("div")((props: { theme: Theme }) => ({
    boxShadow: "0px 1px 2px 1px rgba(203, 222, 227, 0.49)",
    display: "flex",
    borderRadius: "115px",
    padding: "0 0.4rem",
    alignItems: "center",
    fontSize: "8px",
    color: "white",
    backgroundColor: "#02C697",
}));

export const ThumbGrid = styled("div")(
    (props: {
        theme: Theme;
        mode: "single" | "grouped";
        grid?: boolean;
        vertical?: boolean;
        canDrop: boolean;
        isOver: boolean
    } & any) => ({
        display: props.grid === true ? "flex" : "block",
        flexWrap: "wrap",
        // flexDirection       : props.vertical === true ? 'row' : 'column',
        position: "relative",
        zIndex: 0,
        gridGap: "4px",
        borderRadius: "6px",
        margin: "4px",
        marginBottom: "10px",
        padding: props.mode === "grouped" ? "6px 2px" : 0,
        backgroundColor: props.canDrop
            ? props.isOver
                ? "#00b5e2"
                : "#a7edff"
            : props.mode === "grouped"
                ? "#f1f3f7"
                : props.theme.palette.background.default,
        maxWidth: "100%",
        maxHeight: "400px",
        //border         : '1px solid ' + props.theme.skin.borderColor,
        overflowY: "auto",
        "&.hover": {
            backgroundColor: "#a7edff",
        },
    })
);

const TreeContainer = styled("div")(() => ({
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
    zIndex: 0,
    gap: "0.25rem",
    margin: "4px",
    maxWidth: "100%",
    maxHeight: "400px",
    overflowY: "auto",
}));
